package org.example.zone360.pages.coachDetails

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import com.varabyte.kobweb.compose.css.Transition
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.display
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.gap
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.left
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.position
import com.varabyte.kobweb.compose.ui.modifiers.top
import com.varabyte.kobweb.compose.ui.modifiers.transition
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.style.selectors.hover
import org.example.zone360.theme.Palette
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.css.ms
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.rgb
import org.jetbrains.compose.web.css.rgba
import org.w3c.dom.HTMLElement
import org.w3c.dom.events.MouseEvent
import kotlin.math.max
import kotlin.random.Random


val WaveformStyle by ComponentStyle {
    base {
        Modifier
            .display(DisplayStyle.Flex)
            .gap(1.px)
            .height(90.percent)
            .backgroundColor(rgba(241, 245, 249, 0.5))
            .borderRadius(4.px)
    }
}

val WaveBarStyle by ComponentStyle {
    base {
        Modifier
            .backgroundColor(rgba(148, 163, 184, 0.5))
            .width(2.px)
            .borderRadius(1.px)
            .transition(Transition.of(property = "all", duration = 300.ms))
    }
    hover {
        Modifier.backgroundColor(rgb(59, 130, 246))
    }
}

@Composable
fun AudioWaveform(
    modifier: Modifier = Modifier,
    progress: Float = 0f,
    audioDuration: Double = 0.0,
    barsCount: Int = 100, // Configurable bars count with a default value
    isPlaying: Boolean = false,
    onClick: (Double) -> Unit // Callback function to handle seek action
) {
    // Ensure at least one bar is displayed
    val clampedBarsCount = max(1, barsCount)

    // Generate waveform data
    var waveformData by remember { mutableStateOf(generateWaveformData(clampedBarsCount)) }

    // Update waveform data if barsCount changes
    LaunchedEffect(clampedBarsCount) {
        waveformData = generateWaveformData(clampedBarsCount)
    }

    Box(
        modifier = modifier.then(WaveformStyle.toModifier()),
        contentAlignment = Alignment.Center
    ) {
        // Progress overlay
        Box(
            Modifier
                .position(Position.Absolute)
                .left(0.px)
                .top(0.px)
                .height(100.percent)
                .width((progress * 100).percent)
        )

        // Waveform bars
        Row(
            modifier = Modifier.fillMaxWidth().height(100.percent),
            horizontalArrangement = Arrangement.SpaceEvenly,
            verticalAlignment = Alignment.CenterVertically
        ) {
            waveformData.forEachIndexed { index, height ->
                val isActive = index <= (progress * clampedBarsCount)

                Box(
                    modifier = WaveBarStyle.toModifier()
                        .height((height * 100).percent)
                        .backgroundColor(
                            if (isActive) Palette.Primary.rgbLight
                            else Palette.WaveBarColor.rgbLight
                        )
                        .onClick {
                            // Calculate timestamp based on clicked bar
                            val timestamp = (index / clampedBarsCount.toDouble()) * audioDuration
                            onClick(timestamp)
                        }
                )
            }
        }
    }
}

@Composable
fun SentenceAudioWaveform(
    modifier: Modifier = Modifier,
    progress: Float = 0f,
    startTime: Double,
    endTime: Double,
    barsCount: Int = 30, // New parameter for specific number of bars
    onProgressChange: (Float) -> Unit,
    isPlaying: Boolean = false,
) {
    var boxWidthPx by remember { mutableStateOf(0) }
    val duration = endTime - startTime
    val clampedBarsCount = max(1, barsCount) // Ensure at least one bar

    var waveformData by remember { mutableStateOf(generateWaveformData(clampedBarsCount)) }

    Box(
        modifier = modifier.then(WaveformStyle.toModifier())
            .onClick { event ->
                val element = event.target?.unsafeCast<HTMLElement>() ?: return@onClick
                val rect = element.getBoundingClientRect()
                if (rect.width > 0) {
                    val clickX = (event.clientX - rect.left).coerceAtLeast(0.0)
                    val newProgress = (clickX / rect.width).toFloat().coerceIn(0f, 1f)
                    onProgressChange(newProgress)
                }
            },
        contentAlignment = Alignment.Center
    ) {
        // Progress overlay
        Box(
            Modifier
                .position(Position.Absolute)
                .left(0.px)
                .top(0.px)
                .height(100.percent)
                .width((progress * 100).percent)
        )

        // Waveform bars
        Row(
            modifier = Modifier.fillMaxWidth().height(100.percent),
            verticalAlignment = Alignment.CenterVertically,
            horizontalArrangement = Arrangement.SpaceEvenly
        ) {
            waveformData.forEachIndexed { index, height ->
                val isActive = index <= (progress * clampedBarsCount)

                Box(
                    modifier = WaveBarStyle.toModifier()
                        .height((height * 100).percent)
                        .backgroundColor(
                            if (isActive) Palette.Primary.rgbLight
                            else Palette.WaveBarColor.rgbLight
                        )
                )
            }
        }
    }
}


private fun generateWaveformData(bars: Int): List<Float> {
    // Generate random heights between 0.3 and 1.0 for more natural looking waveform
    return List(bars) { Random.nextFloat() * 0.7f + 0.3f }
}
