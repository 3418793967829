package org.example.zone360.pages.players

import androidx.compose.runtime.Composable
import androidx.compose.runtime.DisposableEffect
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.boxShadow
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.gap
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.size
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.icons.fa.FaAngleLeft
import com.varabyte.kobweb.silk.components.icons.fa.FaAngleRight
import com.varabyte.kobweb.silk.components.icons.fa.FaArrowLeft
import com.varabyte.kobweb.silk.components.icons.fa.FaArrowRight
import com.varabyte.kobweb.silk.components.icons.fa.FaPause
import com.varabyte.kobweb.silk.components.icons.fa.FaUpload
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import kotlinx.browser.document
import kotlinx.coroutines.delay
import org.example.zone360.components.CommonButton
import org.example.zone360.components.ProgressIndicator
import org.example.zone360.componentss.FieldTitleTextNormalSimple
import org.example.zone360.pages.coachDetails.AudioWaveform
import org.example.zone360.pages.dashboard.SectionTitle
import org.example.zone360.theme.Palette
import org.example.zone360.theme.SitePalette
import org.example.zone360.utils.Res
import org.example.zone360.utils.SelectedData
import org.example.zone360.utils.UploadedType
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.display
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Iframe
import org.jetbrains.compose.web.dom.Input
import org.jetbrains.compose.web.dom.Source
import org.jetbrains.compose.web.dom.Video
import org.w3c.dom.Audio
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.Image
import org.w3c.files.FileReader
import org.w3c.files.get

@Composable
fun VideosSection(breakpoint: Breakpoint, sitePalette: SitePalette){

    var uploadedVideoUrl by remember { mutableStateOf<String?>(null) }
    var videoUploadedType by remember { mutableStateOf(UploadedType.You) }
    var currentlyPlayingIdFullSession by remember { mutableStateOf<String?>(null) }
    var loadingSessionIdFullSession by remember { mutableStateOf<String?>(null) }
    val currentAudioRefFullSession = remember { mutableStateOf<Audio?>(null) }
    val fullAudio = remember { Audio(Res.Audio.testAudio) }
    val fullPlaySessionId = fullAudio.id
    var fullPlayAudioProgress by remember { mutableStateOf(0f) }

    DisposableEffect(Unit) {
        onDispose {
            if (currentlyPlayingIdFullSession == fullAudio.id) {
                fullAudio.pause()
                currentlyPlayingIdFullSession = null
                fullPlayAudioProgress = 0f
            }
        }
    }

    LaunchedEffect(currentlyPlayingIdFullSession) {
        if (currentlyPlayingIdFullSession != null) {
            val audioPlay = currentAudioRefFullSession.value
            audioPlay?.apply {
                while (currentTime < duration) {
                    fullPlayAudioProgress = (currentTime / duration).toFloat()
                    delay(100)
                }
                // Reset when audio finishes
                if (currentTime >= duration) {
                    currentlyPlayingIdFullSession = null
                    fullPlayAudioProgress = 0f
                }
            }
        }
    }

    Box (modifier = Modifier
        .height(450.px)
        .margin(topBottom = if (breakpoint <= Breakpoint.MD)10.px else 0.px)
        .fillMaxWidth()
        .backgroundColor(sitePalette.modalBackgroundColor.rgb)
        .boxShadow(
            offsetY = 1.px,
            blurRadius = 3.px,
            color = sitePalette.boxShadowColor.rgb
        )
        .border(1.px, style = LineStyle.Solid, color = sitePalette.coachDetailsBoxBorder.rgb)
        .borderRadius(15.px)
        .padding(20.px)
    ) {
        Column(
            modifier = Modifier.fillMaxSize()
        ) {
            Row(
                modifier = Modifier.fillMaxWidth(),
                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.SpaceBetween
            ) {
                SectionTitle("Videos")
                CommonButton(
                    text = "Upload Video",
                    icon = { FaUpload(size = IconSize.LG, modifier = Modifier.color(sitePalette.primary.rgb)) },
                    width = 150.px,
                    height = 40.px,
                    borderColor = sitePalette.primary.rgb,
                    backgroundColor = Colors.Transparent,
                    textSpanColor = sitePalette.primary.rgb,
                    onCLick = {
                        val fileInput = document.getElementById(Res.Id.fileInput) as HTMLInputElement
                        fileInput.click()
                    })
            }
            Row(
                modifier = Modifier.fillMaxWidth(90.percent)
                    .margin(topBottom = 10.px)
                    .borderRadius(6.px)
                    .backgroundColor(sitePalette.lightGrey.rgb)
                    .padding(leftRight = 5.px, topBottom = 4.px)
                    .border(
                        width = 1.px,
                        style = LineStyle.Solid,
                        color = Colors.LightGray
                    ),
                verticalAlignment = Alignment.CenterVertically
            ) {
                Box (modifier = Modifier.fillMaxWidth(50.percent)
                    .borderRadius(4.px)
                    .cursor(Cursor.Pointer)
                    .padding(leftRight = 12.px, topBottom = 6.px)
                    .backgroundColor(if (videoUploadedType == UploadedType.You) Palette.White.rgbLight else Colors.Transparent)
                    .onClick { videoUploadedType = UploadedType.You }
                    .boxShadow(
                        offsetX = 0.px,
                        offsetY = if(videoUploadedType == UploadedType.You)2.px else 0.px,
                        blurRadius = if(videoUploadedType == UploadedType.You)16.px else 0.px,
                        spreadRadius = null,
                        color = sitePalette.boxShadowColor.rgb),
                    contentAlignment = Alignment.Center){
                    FieldTitleTextNormalSimple(
                        text = "Uploaded by You",
                        textAlign = TextAlign.Center,
                        fontWeight = 500,
                        fontSize = 16.px,
                        isClickable = true
                    )
                }
                Box (modifier = Modifier.fillMaxWidth(50.percent)
                    .borderRadius(4.px)
                    .cursor(Cursor.Pointer)
                    .padding(leftRight = 12.px, topBottom = 6.px)
                    .backgroundColor(if (videoUploadedType != UploadedType.You) Palette.White.rgbLight else Colors.Transparent)
                    .onClick { videoUploadedType = UploadedType.SharedByCoach }
                    .boxShadow(
                        offsetX = 0.px,
                        offsetY = if(videoUploadedType == UploadedType.SharedByCoach)2.px else 0.px,
                        blurRadius = if(videoUploadedType == UploadedType.SharedByCoach)16.px else 0.px,
                        spreadRadius = null,
                        color = sitePalette.boxShadowColor.rgb),
                    contentAlignment = Alignment.Center){
                    FieldTitleTextNormalSimple(
                        text = "Shared by Coach",
                        textAlign = TextAlign.Center,
                        fontWeight = 500,
                        fontSize = 16.px,
                        isClickable = true
                    )
                }

            }

                Box(
                modifier = Modifier
                    .fillMaxWidth()
                    .height(270.px)
                    .borderRadius(8.px)
                    .margin(topBottom = 5.px)
                    .align(Alignment.CenterHorizontally)
            ) {
                    Column(modifier = Modifier.fillMaxSize(), horizontalAlignment = Alignment.CenterHorizontally) {
                        if (videoUploadedType == UploadedType.You) {

                            if (uploadedVideoUrl == null) {

                                Iframe(
                                    attrs = {
                                        attr("width", "100%")
                                        attr("height", "100%")
                                        attr(
                                            "src",
                                            "https://www.youtube.com/embed/yEcxh0gEtjE?autoplay=0&loop=1&mute=1&playsinline=1"
                                        )
                                        attr("title", "YouTube video player")
                                        attr("frameborder", "0")
                                        attr(
                                            "allow",
                                            "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        )
                                        attr("allowfullscreen", "true")
                                        style {
                                            property("border", "none")
                                            property("border-radius", "8px")
                                        }
                                    }
                                )
                            } else {
                                // Uploaded video
                                Video(
                                    attrs = {
                                        attr("controls", "true")
                                        attr("autoplay", "false")
                                        attr("loop", "false")
                                        attr("muted", "false")
                                        style {
                                            property("width", "100%")
                                            property("height", "100%")
                                            property("border-radius", "8px")
                                            property("object-fit", "cover")
                                        }
                                    }
                                ) {
                                    Source(
                                        attrs = {
                                            attr("src", uploadedVideoUrl!!)
                                            attr("type", "video/mp4")
                                        }
                                    )
                                }
                            }
                            Row(
                                modifier = Modifier.fillMaxWidth().margin(topBottom = 10.px),
                                verticalAlignment = Alignment.CenterVertically,
                                horizontalArrangement = Arrangement.SpaceBetween
                            ) {
                                Row {
                                    FieldTitleTextNormalSimple(
                                        text = "2/5",
                                        fontSize = 16.px,
                                        fontWeight = 500
                                    )
                                }
                                Row(Modifier.gap(4.px)) {
                                    Image(
                                        src = Res.Images.arrow_Left,
                                        modifier = Modifier.cursor(Cursor.Pointer).onClick { })
                                    Image(
                                        src = Res.Images.arrow_Right,
                                        modifier = Modifier.cursor(Cursor.Pointer).onClick { })
                                }
                            }
                        } else {
                            if (uploadedVideoUrl == null) {
                                Column(modifier = Modifier.fillMaxSize(), verticalArrangement = Arrangement.Center) {
                                    Row(
                                        modifier = Modifier.fillMaxWidth()
                                            .height(100.px)
                                            .borderRadius(8.px)
                                            .padding(8.px)
                                            .gap(8.px)

                                            .backgroundColor(sitePalette.searchBackground.rgb),
                                        verticalAlignment = Alignment.CenterVertically
                                    ) {
                                        if (loadingSessionIdFullSession == fullAudio.id) {
                                            Box(
                                                modifier = Modifier.size(30.px)
                                                    .borderRadius(100.percent)
                                                    .backgroundColor(sitePalette.primary.rgb),
                                                contentAlignment = Alignment.Center
                                            ) {
                                                ProgressIndicator(10.px)
                                            }
                                        } else if (currentlyPlayingIdFullSession == fullAudio.id) {
                                            Box(
                                                modifier = Modifier.size(30.px)
                                                    .borderRadius(100.percent)
                                                    .cursor(Cursor.Pointer)
                                                    .backgroundColor(sitePalette.primary.rgb)
                                                    .onClick {
                                                        fullAudio.pause()
                                                        currentlyPlayingIdFullSession = null
                                                        currentAudioRefFullSession.value = null
                                                        fullPlayAudioProgress = 0f
                                                    },
                                                contentAlignment = Alignment.Center
                                            ) {
                                                FaPause(
                                                    size = IconSize.XXS,
                                                    modifier = Modifier.color(Colors.White)
                                                )
                                            }
                                        } else {
                                            Image(
                                                src = Res.Images.play_all_button,
                                                modifier = Modifier.size(30.px)
                                                    .cursor(Cursor.Pointer)
                                                    .onClick {

                                                        currentAudioRefFullSession.value?.pause()
                                                        loadingSessionIdFullSession =
                                                            fullPlaySessionId

                                                        fullAudio.onloadeddata = {
                                                            loadingSessionIdFullSession = null
                                                            fullAudio.play()
                                                            currentlyPlayingIdFullSession =
                                                                fullPlaySessionId
                                                            fullAudio.also {
                                                                currentAudioRefFullSession.value =
                                                                    it
                                                            }
                                                        }
                                                        fullAudio.load()
                                                    }
                                            )
                                        }
                                        AudioWaveform(
                                            modifier = Modifier.fillMaxWidth(),
                                            progress = fullPlayAudioProgress,
                                            audioDuration = currentAudioRefFullSession.value?.duration
                                                ?: 0.0,
                                            onClick = { time ->
                                                fullAudio.currentTime = time
                                            },
                                            barsCount = 50
                                        )

                                    }
                                    Row(
                                        modifier = Modifier.fillMaxWidth().margin(topBottom = 10.px),
                                        verticalAlignment = Alignment.CenterVertically,
                                        horizontalArrangement = Arrangement.SpaceBetween
                                    ) {
                                        Row {
                                            FieldTitleTextNormalSimple(
                                                text = "2/5",
                                                fontSize = 16.px,
                                                fontWeight = 500
                                            )
                                        }
                                        Row(Modifier.gap(4.px)) {
                                            Image(
                                                src = Res.Images.arrow_Left,
                                                modifier = Modifier.cursor(Cursor.Pointer).onClick { })
                                            Image(
                                                src = Res.Images.arrow_Right,
                                                modifier = Modifier.cursor(Cursor.Pointer).onClick { })
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
        }
    }
    Input(
        type = InputType.File,
        attrs = {
            id(Res.Id.fileInput)
            style {
                display(DisplayStyle.None)
            }
            onChange { event ->
                val files = (event.target as HTMLInputElement).files
                if (files != null && files.length > 0) {
                    val file = files[0]
                    if (file != null) {
                        val reader = FileReader()
                        reader.onload = { e ->
                            val result = e.target?.asDynamic()?.result as? String
                            if (result != null) {
                                uploadedVideoUrl = result
                            }
                        }
                        reader.readAsDataURL(file)
                    }
                }
            }
        }
    )
}

