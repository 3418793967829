package org.example.zone360.models

import kotlinx.serialization.Serializable
@Serializable
data class GetClubPlayerAssessmentsResponse (
    val data: List<GetClubPlayerAssessmentData>
)
@Serializable
data class GetClubPlayerAssessmentData(
    val coachName: String = "",
    val count: Int = 0 ,
    val keyInsights: String? = "",
    val playerName: String = "",
    val sessionDateTime: String = "",
    val userID: String = ""
)