package org.example.zone360.components

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.toAttrs
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Input

@Composable
fun AudioProgressSliderCustom(
    progress: Float, // Current audio progress as a percentage (0.0 to 1.0)
    onProgressChange: (Float) -> Unit, // Called when the user drags the slider
) {
    // Create a Slider UI element
    Input(
        type = InputType.Range,
        attrs = Modifier.height(2.px).toAttrs {
            attr("value", (progress * 100).toString()) // Multiply by 100 for percentage
            attr("min", "0")
            attr("max", "100")
            attr("step", "1")
            onInput {
                // Handle slider input change
                val newProgress = it.value?.toFloat()
                println("newProgress: $newProgress")
                if (newProgress != null) {
                    // Update the progress based on the slider position
                    onProgressChange(newProgress / 100) // Convert back to 0.0 - 1.0 range
                }
            }
        },
    )
}


