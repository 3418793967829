package org.example.zone360.pages.coachDetails.sessionAllDetails

import JsonConfig
import androidx.compose.runtime.Composable
import androidx.compose.runtime.DisposableEffect
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.css.WhiteSpace
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.boxShadow
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxHeight
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.flexWrap
import com.varabyte.kobweb.compose.ui.modifiers.gap
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.maxHeight
import com.varabyte.kobweb.compose.ui.modifiers.minHeight
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.overflow
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.size
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.icons.fa.FaChevronDown
import com.varabyte.kobweb.silk.components.icons.fa.FaChevronUp
import com.varabyte.kobweb.silk.components.icons.fa.FaHourglassEnd
import com.varabyte.kobweb.silk.components.icons.fa.FaPause
import com.varabyte.kobweb.silk.components.icons.fa.FaUpload
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import getClubPlayer
import getSentences
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import org.example.zone360.components.CommonButton
import org.example.zone360.components.CustomDivider
import org.example.zone360.components.CustomSpacer
import org.example.zone360.components.ProgressIndicator
import org.example.zone360.components.formatSessionDate
import org.example.zone360.components.formatSessionHours
import org.example.zone360.components.formatSessionMinutes
import org.example.zone360.componentss.FieldTitleTextNormalSimple
import org.example.zone360.models.GetClubPlayerData
import org.example.zone360.models.GetSentencesData
import org.example.zone360.models.GetSessionsData
import org.example.zone360.models.Sentimentprob
import org.example.zone360.models.SessionDataDetailsResponse
import org.example.zone360.pages.coachDetails.AudioWaveform
import org.example.zone360.pages.coachDetails.SentenceAudioWaveform
import org.example.zone360.pages.coachDetails.charts.CircularProgressIndicator
import org.example.zone360.pages.coachDetails.charts.RadarChartPage
import org.example.zone360.pages.dashboard.SectionTitle
import org.example.zone360.theme.Palette
import org.example.zone360.theme.SitePalette
import org.example.zone360.utils.AppGlobal
import org.example.zone360.utils.Constants
import org.example.zone360.utils.Res
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.FlexWrap
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.display
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Input
import org.w3c.dom.Audio
import org.w3c.dom.HTMLInputElement
import org.w3c.files.FileReader
import org.w3c.files.get

@Composable
fun SessionAllDetails(
    breakpoint: Breakpoint,
    sitePalette: SitePalette,
    sessionData: GetSessionsData,

) {
    println("sessionData: ${sessionData.sessionData}")
    val parts = sessionData.sessionId.removeSuffix(".aac").split("_")
    val sessionName = parts.first()
    if (sessionData.sessionData.isNotEmpty()) {

        val scope = rememberCoroutineScope()
        val getSentences = remember { mutableStateListOf<GetSentencesData>() }
        var selectedPlayers by remember {
            mutableStateOf<Map<String, List<GetClubPlayerData>>>(
                emptyMap()
            )
        }
        val selectedPlayersFullSession = remember { mutableStateListOf<GetClubPlayerData>() }
//    val getSessions = remember { mutableStateListOf<GetSessionsData>() }

        val obj =
            JsonConfig.default.decodeFromString<SessionDataDetailsResponse>(sessionData.sessionData)
        val keywordExtraction = obj.response.keywordExtraction
        val sentiments = obj.response.sentimentAnalysis
        val toneAnalysis = obj.response.toneAnalysis
        val emotionAnalysis = obj.response.emotionAnalysis
        val summaryData = obj.response.summary

        val dominantEmotion = emotionAnalysis.emotionPredictions
            .flatMap {
                listOf(
                    it.emotion.anger to "Anger",
                    it.emotion.joy to "Joy",
                    it.emotion.sadness to "Sadness",
                    it.emotion.disgust to "Disgust",
                    it.emotion.fear to "Fear"
                )
            }
            .maxByOrNull { it.first } // Find the maximum value
            ?.second ?: "Unknown"
        val dominantTone = toneAnalysis.classes
            .maxByOrNull { it.confidence } // Find the class with the highest confidence
            ?.className ?: "Unknown"
        // Aggregate sentiment probabilities from sentimentMentions
        val sentimentMentionCounts = sentiments.documentSentiment.sentimentMentions.size
        val aggregatedSentiments = sentiments.documentSentiment.sentimentMentions.fold(
            Sentimentprob(
                0.0,
                0.0,
                0.0
            )
        ) { acc, mention ->
            Sentimentprob(
                positive = acc.positive + mention.sentimentprob.positive,
                negative = acc.negative + mention.sentimentprob.negative,
                neutral = acc.neutral + mention.sentimentprob.neutral
            )
        }

        // Average the aggregated sentiments
        val averageSentiments = Sentimentprob(
            positive = aggregatedSentiments.positive / sentimentMentionCounts,
            negative = aggregatedSentiments.negative / sentimentMentionCounts,
            neutral = aggregatedSentiments.neutral / sentimentMentionCounts
        )

        // Convert to percentages
        val positivePercentage = (averageSentiments.positive * 100).toInt()
        val negativePercentage = (averageSentiments.negative * 100).toInt()
        val neutralPercentage = (averageSentiments.neutral * 100).toInt()

        var searchText by remember { mutableStateOf("") }
        var uploadedVideoUrl by remember { mutableStateOf<String?>(null) }
        var expandedIndex by remember { mutableStateOf(-1) }
        var isExpanded by remember { mutableStateOf(false) }

        var players by remember { mutableStateOf<List<GetClubPlayerData>>(emptyList()) }
        var isLoading by remember { mutableStateOf(true) }
        var error by remember { mutableStateOf<String?>(null) }

//    audio
        var currentlyPlayingIdFullSession by remember { mutableStateOf<String?>(null) }
        var loadingSessionIdFullSession by remember { mutableStateOf<String?>(null) }
        val currentAudioRefFullSession = remember { mutableStateOf<Audio?>(null) }
        val fullPlaySessionId = sessionData.sessionId
        val fullAudio = remember { Audio("${Res.Audio.recording_Audio}${sessionData.sessionId}") }
        val getSentenceAudio =  Audio("${Res.Audio.recording_Audio}${sessionData.sessionId}")
        var fullPlayAudioProgress by remember { mutableStateOf(0f) }


        val playAllAudio = Audio("${Res.Audio.recording_Audio}${sessionData.sessionId}")

//
        var isPlayingAllHighlight by remember { mutableStateOf(false) }
        var currentlyPlayingIndexHighlight by remember { mutableStateOf<Int?>(null) }
        var currentlyPlayingIdHighlight by remember { mutableStateOf<String?>(null) }
        var loadingSessionIdHighlight by remember { mutableStateOf<String?>(null) }
        val currentAudioRefHighlight = remember { mutableStateOf<Audio?>(null) }
        var audioProgress by remember { mutableStateOf(0f) }


        DisposableEffect(Unit) {
            onDispose {
                if (currentlyPlayingIdFullSession == sessionData.sessionId) {
                    fullAudio.pause()
                    currentlyPlayingIdFullSession = null
                    fullPlayAudioProgress = 0f
                }
            }
        }

        LaunchedEffect(currentlyPlayingIdFullSession) {
            if (currentlyPlayingIdFullSession != null) {
                val audioPlay = currentAudioRefFullSession.value
                audioPlay?.apply {
                    while (currentTime < duration) {
                        fullPlayAudioProgress = (currentTime / duration).toFloat()
                        delay(100)
                    }
                    // Reset when audio finishes
                    if (currentTime >= duration) {
                        currentlyPlayingIdFullSession = null
                        fullPlayAudioProgress = 0f
                    }
                }
            }
        }

        // Ensure this is only triggered once during composition
        LaunchedEffect(Unit) {
            try {
                isLoading = true
                error = null
                val response = getClubPlayer(AppGlobal.userData.organization)
                players = response.data

                //
                val taggedPlayers = sessionData.tagPlayers
                println("taggedPlayersS: $taggedPlayers")

                players.forEach {
                    println("players: $it")
                }
                if (taggedPlayers?.isNotEmpty() == true) {
                    taggedPlayers.forEach { player ->
                        val filteredPlayers = players.filter { it.uid == player }
                        println("filteredPlayersS: $filteredPlayers")
                        if (filteredPlayers.isNotEmpty()) {
//                    selectedPlayersFullSession.clear()
                            selectedPlayersFullSession.add(filteredPlayers.first())
                        }
                    }
                }
                selectedPlayersFullSession.forEach {
                    println("selectedPlayersFullSession: $it")
                }
//            println("selectedPlayersSessionS: $selectedPlayersFullSession")
            } catch (e: Exception) {
                error = "Failed to load players: ${e.message}"
                println("Error loading players: ${e.message}")
            } finally {
                isLoading = false
            }
        }

// Only re-fetch sentences when `sessionData.sessionId` changes
        LaunchedEffect(sessionData.sessionId) {
            try {
                isLoading = true
                val getSentencesResponse = getSentences(
                    sessionID = sessionData.sessionId,
                    type = "sentence"
                )
                getSentences.clear()
                getSentences.addAll(getSentencesResponse.data)
            } catch (e: Exception) {
                println("Error loading sentences: ${e.message}")
            } finally {
                isLoading = false
            }
        }


        Box(
            modifier = Modifier
                .width(
                    if (breakpoint > Breakpoint.LG) 65.percent else if (breakpoint == Breakpoint.LG) 59.percent
                    else if (breakpoint >= Breakpoint.MD) 49.percent else 1360.px
                )
                .margin(topBottom = if (breakpoint <= Breakpoint.MD) 10.px else 0.px)
                .backgroundColor(sitePalette.modalBackgroundColor.rgb)
                .borderRadius(15.px)
                .overflow(
                    overflowY = Overflow.Hidden,
                    overflowX = Overflow.Auto
                )
                .boxShadow(
                    offsetY = 1.px,
                    blurRadius = 3.px,
                    color = sitePalette.boxShadowColor.rgb
                )
                .padding(
                    leftRight = if (breakpoint >= Breakpoint.LG) 30.px else 20.px,
                    topBottom = if (breakpoint >= Breakpoint.LG) 20.px else 10.px
                )
        ) {
            Column(
                modifier = Modifier.width(if (breakpoint <= Breakpoint.LG) 1360.px else 100.percent)
                    .fillMaxHeight()
            ) {
                Row(
                    modifier = Modifier.fillMaxWidth(),
                    verticalAlignment = Alignment.CenterVertically,
                    horizontalArrangement = Arrangement.SpaceBetween
                ) {
                    SectionTitle(sessionName.replaceFirstChar { if (it.isLowerCase()) it.titlecase() else it.toString() })
                    CommonButton(
                        text = "Upload Video",
                        icon = {
                            FaUpload(
                                size = IconSize.LG,
                                modifier = Modifier.color(sitePalette.primary.rgb)
                            )
                        },
                        width = 150.px,
                        height = 40.px,
                        borderColor = sitePalette.primary.rgb,
                        backgroundColor = Colors.Transparent,
                        textSpanColor = sitePalette.primary.rgb,
                        onCLick = {
                            val fileInput =
                                document.getElementById(Res.Id.fileInput) as HTMLInputElement
                            fileInput.click()
                        })
                }
                Row(
                    modifier = Modifier.fillMaxWidth().gap(36.px),
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    Row(verticalAlignment = Alignment.CenterVertically) {
//                    Image(
//                        src = Res.Images.coachPic,
//                        modifier = Modifier.size(30.px).margin(right = 8.px)
//                            .borderRadius(100.px).textAlign(TextAlign.Start)
//                            .border(
//                                width = 2.px,
//                                color = sitePalette.primary.rgb,
//                                style = LineStyle.Solid
//                            )
//                    )
                        FieldTitleTextNormalSimple(
                            text = "Coach:  ",
                            fontFamily = Constants.IBM_FONT,
                            fontSize = 16.px
                        )
                        FieldTitleTextNormalSimple(
                            text = sessionData.coachName,
                            fontWeight = 600,
                            fontFamily = Constants.IBM_FONT,
                            fontSize = 16.px
                        )
                    }

                    Row(
                        verticalAlignment = Alignment.CenterVertically
                    ) {
                        Image(src = Res.Images.timeCircle, modifier = Modifier.size(17.px))
                        CustomSpacer(5.px)
                        FieldTitleTextNormalSimple(
                            text = "Duration:  ",
                            fontFamily = Constants.IBM_FONT,
                            fontSize = 16.px
                        )
                        FieldTitleTextNormalSimple(
                            text = sessionData.recordTime.toLongOrNull()?.let {
                                (it / 1000).formatSessionMinutes()
                            } ?: "Invalid Time",
                            fontWeight = 600,
                            fontFamily = Constants.IBM_FONT,
                            fontSize = 16.px
                        )
                    }
                    Row(
                        verticalAlignment = Alignment.CenterVertically
                    ) {
                        Image(src = Res.Images.calender, modifier = Modifier.size(17.px))
                        CustomSpacer(5.px)
                        FieldTitleTextNormalSimple(
                            text = "Recorded:  ",
                            fontFamily = Constants.IBM_FONT,
                            fontSize = 16.px
                        )
                        FieldTitleTextNormalSimple(
                            text = "${sessionData.sessionDateTime.formatSessionDate()} ${sessionData.sessionDateTime.formatSessionHours()}",
                            fontWeight = 600,
                            fontFamily = Constants.IBM_FONT,
                            fontSize = 16.px
                        )
                    }
                }

                Row(
                    modifier = Modifier.fillMaxWidth()
                        .flexWrap(FlexWrap.Wrap),
                    verticalAlignment = Alignment.CenterVertically,
                    horizontalArrangement = Arrangement.SpaceBetween
                ) {
// Speaking Box
                    Box(
                        Modifier.width(if (window.screen.width in 1280.. 1420 )49.percent else 32.percent)
                            .height(370.px)
                            .margin(top = 15.px)
                            .borderRadius(12.px)
                            .border(
                                1.px,
                                style = LineStyle.Solid,
                                color = sitePalette.coachDetailsBoxBorder.rgb
                            )
                            .padding(16.px)
                            .boxShadow(
                                offsetY = 1.px,
                                blurRadius = 0.px,
                                color = sitePalette.fadedBlack.rgb

                            )
                    ) {
                        SectionTitle("Speaking")
                        Column(
                            modifier = Modifier.fillMaxSize().margin(right = 30.px),
                            verticalArrangement = Arrangement.Center,
                        ) {
                            Row(
                                modifier = Modifier.fillMaxWidth(),
                                verticalAlignment = Alignment.CenterVertically,
                                horizontalArrangement = Arrangement.SpaceBetween
                            ) {
                                Column(modifier = Modifier.gap(24.px)) {
                                    Row(
                                        verticalAlignment = Alignment.CenterVertically
                                    ) {
                                        FaHourglassEnd(
                                            modifier = Modifier.color(sitePalette.primary.rgb)
                                                .margin(right = 4.px, bottom = (-7).px),
                                            size = IconSize.LG
                                        )
                                        FieldTitleTextNormalSimple(
                                            text = "Total Duration:  ",
                                            fontFamily = Constants.IBM_FONT,
                                            fontSize = 16.px
                                        )
                                        FieldTitleTextNormalSimple(
                                            text = sessionData.recordTime.toLongOrNull()?.let {
                                                (it / 1000).formatSessionMinutes()
                                            } ?: "Invalid Time",
                                            fontWeight = 600,
                                            fontFamily = Constants.IBM_FONT,
                                            fontSize = 16.px
                                        )
                                    }
                                    Row(verticalAlignment = Alignment.CenterVertically) {
                                        Image(
                                            src = Res.Images.recording_Voice,
                                            modifier = Modifier.size(17.px).margin(right = 4.px)
                                        )
                                        FieldTitleTextNormalSimple(
                                            text = "Speaking: ",
                                            fontFamily = Constants.IBM_FONT,
                                            fontSize = 16.px
                                        )
                                        FieldTitleTextNormalSimple(
                                            text = sessionData.speakingTime.formatSessionMinutes(),
                                            fontWeight = 600,
                                            fontFamily = Constants.IBM_FONT,
                                            fontSize = 16.px
                                        )
                                    }
                                }


                            }
                            Box(
                                modifier = Modifier.fillMaxWidth().margin(top = 50.px),
                                contentAlignment = Alignment.Center
                            ) {
                                val recordTimeInSeconds = sessionData.recordTime.toLongOrNull()
                                    ?.let { it / 1000 }  // Convert to seconds
                                val percentage =
                                    (sessionData.speakingTime / recordTimeInSeconds!!.toDouble()) * 100
                                CircularProgressIndicator(
                                    percentage = percentage.toFloat(),
                                    size = 102,       // Now just pass Int
                                    strokeWidth = 10,  // Now just pass Int
                                    primaryColor = sitePalette.primary.rgb,
                                    backgroundColor = sitePalette.lightGrey.rgb
                                )
                            }

                        }
                    }
// Emotion Box
                    Box(
                        modifier = Modifier.width(if (window.screen.width in 1280.. 1420 )49.percent else 32.percent)
                            .height(370.px)
                            .margin(top = 15.px)
                            .borderRadius(12.px)
                            .border(
                                1.px,
                                style = LineStyle.Solid,
                                color = sitePalette.coachDetailsBoxBorder.rgb
                            )
                            .padding(16.px)
                            .boxShadow(
                                offsetY = 1.px,
                                blurRadius = 0.px,
                                color = sitePalette.fadedBlack.rgb

                            )
                    ) {
                        Column(modifier = Modifier.fillMaxSize()) {
                            SectionTitle("Emotion")
                            if (isLoading) {
                                Box(
                                    modifier = Modifier.fillMaxSize(),
                                    contentAlignment = Alignment.Center
                                ) {
                                    ProgressIndicator(30.px)
                                }
                            } else {
                                Column(
                                    modifier = Modifier.fillMaxWidth(),
                                    horizontalAlignment = Alignment.CenterHorizontally
                                ) {
                                    RadarChartPage(sessionData)
//
                                }
                            }
                        }
                    }

//  Sentiment Box
                    Box(
                        modifier = Modifier.width(if (window.screen.width in 1280.. 1420 )49.percent else 32.percent)
                            .height(370.px)
                            .margin(top = 15.px)
                            .borderRadius(12.px)
                            .border(
                                1.px,
                                style = LineStyle.Solid,
                                color = sitePalette.coachDetailsBoxBorder.rgb
                            )
                            .padding(16.px)
                            .boxShadow(
                                offsetY = 1.px,
                                blurRadius = 0.px,
                                color = sitePalette.fadedBlack.rgb

                            )
                    ) {
                        Column(modifier = Modifier.fillMaxWidth()) {
                            SectionTitle("Sentiments")
                            Row(
                                modifier = Modifier.fillMaxWidth()
                                    .borderRadius(8.px),
                                verticalAlignment = Alignment.CenterVertically
                            ) {
                                Row(
                                    modifier = Modifier
                                        .fillMaxWidth()
                                        .height(8.px)
                                        .borderRadius(8.px)

                                ) {

                                    Box(
                                        modifier = Modifier
                                            .fillMaxHeight()
                                            .borderRadius(topLeft = 8.px, bottomLeft = 8.px)
                                            .width(positivePercentage.percent)
                                            .backgroundColor(Palette.PositiveColor.rgbLight)
                                    )
                                    Box(
                                        modifier = Modifier
                                            .fillMaxHeight()
                                            .width(negativePercentage.percent)
                                            .backgroundColor(Palette.NegativeColor.rgbLight)
                                    )
                                    Box(
                                        modifier = Modifier
                                            .fillMaxHeight()
                                            .borderRadius(topRight = 8.px, bottomRight = 8.px)
                                            .width(neutralPercentage.percent)
                                            .backgroundColor(Palette.NeutralColor.rgbLight)
                                    )
                                }

                            }
                            Row(
                                modifier = Modifier.fillMaxWidth().margin(topBottom = 10.px)
                                    .gap(8.px)
                                    .flexWrap(FlexWrap.Wrap),
                                verticalAlignment = Alignment.CenterVertically,
                            ) {
                                Row(verticalAlignment = Alignment.CenterVertically) {
                                    Box(
                                        modifier = Modifier.size(10.px)
                                            .borderRadius(1.67.px)
                                            .backgroundColor(Palette.PositiveColor.rgbLight)
                                    )
                                    FieldTitleTextNormalSimple(
                                        text = "Positive: $positivePercentage%",
                                        marginLeft = 10.px,
                                        fontSize = 12.px
                                    )
                                }
                                Row(verticalAlignment = Alignment.CenterVertically) {
                                    Box(
                                        modifier = Modifier.size(10.px)
                                            .borderRadius(1.67.px)
                                            .backgroundColor(Palette.NegativeColor.rgbLight)
                                    )
                                    FieldTitleTextNormalSimple(
                                        text = "Negative: $negativePercentage%",
                                        marginLeft = 10.px,
                                        fontSize = 12.px
                                    )
                                }
                                Row(verticalAlignment = Alignment.CenterVertically) {
                                    Box(
                                        modifier = Modifier.size(10.px)
                                            .borderRadius(1.67.px)
                                            .backgroundColor(Palette.NeutralColor.rgbLight)
                                    )
                                    FieldTitleTextNormalSimple(
                                        text = "Neutral: $neutralPercentage%",
                                        marginLeft = 10.px,
                                        fontSize = 12.px
                                    )
                                }
                            }

                            FieldTitleTextNormalSimple(
                                text = "Tone",
                                fontSize = 20.px,
                                fontFamily = Constants.IBM_FONT,
                                fontWeight = 600,
                            )
                            Column(
                                modifier = Modifier.gap(8.px).fillMaxWidth().margin(bottom = 10.px)
                                    .overflow(
                                        overflowY = Overflow.Auto,
                                        overflowX = Overflow.Hidden
                                    )
                            ) {
                                // Dynamically generate rows based on toneAnalysis data
                                toneAnalysis.classes.forEach { tone ->
                                    Row(verticalAlignment = Alignment.CenterVertically) {
                                        FieldTitleTextNormalSimple(
                                            text = "${getEmoji(tone.className)} ${tone.className}", // Name of the tone
                                            width = 100.px,
                                            fontSize = 14.px,
                                            fontFamily = Constants.IBM_FONT
                                        )
                                        CustomSpacer(10.px)
                                        Box(
                                            modifier = Modifier
                                                .width((tone.confidence * 238).px) // Adjust width based on confidence (scaled)
                                                .height(22.px)
                                                .borderRadius(bottomRight = 2.92.px)
                                                .backgroundColor(Palette.NeutralColor.rgbLight)
                                        )
                                        FieldTitleTextNormalSimple(
                                            text = "${(tone.confidence * 100).toInt()}%", // Confidence percentage
                                            fontSize = 12.px,
                                            fontFamily = Constants.IBM_FONT,
                                            marginLeft = 5.px
                                        )
                                    }
                                }
                            }
                        }
                    }
                }

                //            Summary Box
                Column(
                    modifier = Modifier.fillMaxWidth()
                        .height(400.px)
                        .margin(top = 15.px)
                        .borderRadius(12.px)
                        .border(
                            1.px,
                            style = LineStyle.Solid,
                            color = sitePalette.coachDetailsBoxBorder.rgb
                        )
                        .padding(16.px)
                        .boxShadow(
                            offsetY = 1.px,
                            blurRadius = 0.px,
                            color = sitePalette.fadedBlack.rgb
                        )
                ) {
                        SectionTitle("Summary")
                    Column (modifier = Modifier.fillMaxWidth()
                        .maxHeight(360.px)
                        .overflow(overflowY = Overflow.Auto, overflowX = Overflow.Hidden)){
                        FieldTitleTextNormalSimple(
                            width = 100.percent,
                            whiteSpace = WhiteSpace.PreWrap,
                            fontFamily = Constants.IBM_FONT,
                            fontSize = 16.px,
                            text = summaryData.split("\n").joinToString("\n") { it.trim() }
                        )
                    }

                }

//  Highlights Box
                Box(
                    modifier = Modifier.fillMaxWidth()
                        .height(975.px)
                        .margin(top = 15.px)
                        .borderRadius(12.px)
                        .border(
                            1.px,
                            style = LineStyle.Solid,
                            color = sitePalette.coachDetailsBoxBorder.rgb
                        )
                        .padding(16.px)
                        .boxShadow(
                            offsetY = 1.px,
                            blurRadius = 0.px,
                            color = sitePalette.fadedBlack.rgb
                        )
                ) {
                    if (isLoading) Box(
                        modifier = Modifier.fillMaxSize(),
                        contentAlignment = Alignment.Center
                    ) {
                        ProgressIndicator(50.px)
                    } else {
                        Column(modifier = Modifier.fillMaxSize()) {

                            SectionTitle("Full Session")
                            Row(
                                modifier = Modifier.fillMaxWidth()
                                    .height(40.px)
                                    .borderRadius(8.px)
                                    .padding(8.px)
                                    .gap(8.px)
                                    .backgroundColor(sitePalette.searchBackground.rgb),
                                verticalAlignment = Alignment.CenterVertically
                            ) {
                                if (loadingSessionIdFullSession == sessionData.sessionId) {
                                    Box(
                                        modifier = Modifier.size(30.px)
                                            .borderRadius(100.percent)
                                            .backgroundColor(sitePalette.primary.rgb),
                                        contentAlignment = Alignment.Center
                                    ) {
                                        ProgressIndicator(10.px)
                                    }
                                } else if (currentlyPlayingIdFullSession == sessionData.sessionId) {
                                    Box(
                                        modifier = Modifier.size(30.px)
                                            .borderRadius(100.percent)
                                            .cursor(Cursor.Pointer)
                                            .backgroundColor(sitePalette.primary.rgb)
                                            .onClick {
                                                fullAudio.pause()
                                                currentlyPlayingIdFullSession = null
                                                currentAudioRefFullSession.value = null
                                                fullPlayAudioProgress = 0f
                                            },
                                        contentAlignment = Alignment.Center
                                    ) {
                                        FaPause(
                                            size = IconSize.XXS,
                                            modifier = Modifier.color(Colors.White)
                                        )
                                    }
                                } else {
                                    Image(
                                        src = Res.Images.play_all_button,
                                        modifier = Modifier.size(30.px)
                                            .cursor(Cursor.Pointer)
                                            .onClick {
                                                currentAudioRefFullSession.value?.pause()
                                                loadingSessionIdFullSession = fullPlaySessionId

                                                fullAudio.onloadeddata = {
                                                    loadingSessionIdFullSession = null
                                                    fullAudio.play()
                                                    playAllAudio.pause()
                                                    getSentenceAudio.pause()
                                                    currentlyPlayingIdFullSession =
                                                        fullPlaySessionId
                                                    fullAudio.also {
                                                        currentAudioRefFullSession.value = it
                                                    }
                                                }
                                                fullAudio.load()
                                            }
                                    )
                                }
                                AudioWaveform(
                                    modifier = Modifier.fillMaxWidth(),
                                    progress = fullPlayAudioProgress,
                                    audioDuration = currentAudioRefFullSession.value?.duration
                                        ?: 0.0,
                                    onClick = { time ->
                                        fullAudio.currentTime = time
                                    },
                                    barsCount = 200
                                )

                            }
                            Column(
                                modifier = Modifier.fillMaxWidth().maxHeight(250.px)
                                    .minHeight(100.px).overflow(
                                        overflowY = Overflow.Auto,
                                        overflowX = Overflow.Hidden
                                    )
                            ) {
                                Row(
                                    modifier = Modifier.fillMaxWidth().margin(topBottom = 10.px)
                                        .flexWrap(FlexWrap.Wrap)
                                        .overflow(
                                            overflowY = Overflow.Auto,
                                            overflowX = Overflow.Hidden
                                        )
                                ) {
                                    // Create a map to store unique keywords and their total counts and categories
                                    val uniqueKeywords = mutableMapOf<String, Pair<Int, String>>()

                                    // Aggregate counts and preserve category for each unique keyword
                                    keywordExtraction.views.forEach { view ->
                                        view.properties.forEach { property ->
                                            val keyword =
                                                property.aqlProperty.match.text.lowercase() // Normalize to lowercase
                                            val keywordCategory = property.aqlProperty.label
                                            val (currentCount, _) = uniqueKeywords[keyword] ?: Pair(
                                                0,
                                                keywordCategory
                                            )
                                            uniqueKeywords[keyword] =
                                                Pair(currentCount + 1, keywordCategory)
                                        }
                                    }

                                    // Display unique keywords with their total counts
                                    uniqueKeywords.forEach { (keyword, countAndCategory) ->
                                        val (count, keywordCategory) = countAndCategory

                                        Box {
                                            Box(
                                                modifier = Modifier.height(32.px)
                                                    .backgroundColor(
                                                        when (keywordCategory) {
                                                            "fitness" -> sitePalette.fitnessCategoryBackground.rgb
                                                            "mental" -> sitePalette.mentalCategoryBackground.rgb
                                                            "technical" -> sitePalette.technicalCategoryBackground.rgb
                                                            "tactical" -> sitePalette.tacticalCategoryBackground.rgb
                                                            "goalkeeper" -> sitePalette.goalKeeperCategoryBackground.rgb
                                                            else -> sitePalette.textErrorColor.rgb
                                                        }
                                                    )
                                                    .borderRadius(7.px)
                                                    .padding(leftRight = 8.px, topBottom = 4.px)
                                                    .border(
                                                        1.px, color =
                                                        when (keywordCategory) {
                                                            "fitness" -> sitePalette.fitnessCategoryBorder.rgb
                                                            "mental" -> sitePalette.mentalCategoryBorder.rgb
                                                            "technical" -> sitePalette.technicalCategoryBorder.rgb
                                                            "tactical" -> sitePalette.tacticalCategoryBorder.rgb
                                                            "goalkeeper" -> sitePalette.goalKeeperCategoryBorder.rgb
                                                            else -> sitePalette.textErrorColor.rgb
                                                        }, style = LineStyle.Solid
                                                    )
                                                    .margin(7.px),
                                                contentAlignment = Alignment.Center
                                            ) {
                                                FieldTitleTextNormalSimple(
                                                    keyword.replaceFirstChar { if (it.isLowerCase()) it.titlecase() else it.toString() }
                                                )
                                            }
                                            Box(
                                                modifier = Modifier.size(18.px)
                                                    .margin(right = (-5).px)
                                                    .align(Alignment.TopEnd)
                                                    .backgroundColor(
                                                        when (keywordCategory) {
                                                            "fitness" -> sitePalette.fitnessCategoryBorder.rgb
                                                            "mental" -> sitePalette.mentalCategoryBorder.rgb
                                                            "technical" -> sitePalette.technicalCategoryBorder.rgb
                                                            "tactical" -> sitePalette.tacticalCategoryBorder.rgb
                                                            "goalkeeper" -> sitePalette.goalKeeperCategoryBorder.rgb
                                                            else -> sitePalette.textErrorColor.rgb
                                                        }
                                                    )
                                                    .borderRadius(100.px),
                                                contentAlignment = Alignment.Center
                                            ) {
                                                FieldTitleTextNormalSimple(
                                                    text = count.toString(),
                                                    color = Colors.White,
                                                    fontSize = 10.px
                                                )
                                            }
                                        }
                                    }
                                }
                            }
                            CustomDivider()
                            CustomSpacer(height = 15.px)
                            Row(
                                modifier = Modifier.fillMaxWidth(),
                                verticalAlignment = Alignment.CenterVertically,
                                horizontalArrangement = Arrangement.SpaceBetween
                            ) {
                                SectionTitle("Highlights")
//                                Row(
//                                    modifier = Modifier
//                                        .width(150.px)
//                                        .height(36.px)
//                                        .borderRadius(4.px)
//                                        .backgroundColor(sitePalette.searchBackground.rgb)
//                                        .padding(topBottom = 10.px, leftRight = 8.px)
//                                        .gap(8.px),
//                                    verticalAlignment = Alignment.CenterVertically
//                                ) {
//                                    Box(contentAlignment = Alignment.Center) {
//                                        if (isPlayingAllHighlight) {
//                                            // Stop "Play All Highlights" Button
//                                            Box(
//                                                modifier = Modifier
//                                                    .size(27.px)
//                                                    .borderRadius(100.percent)
//                                                    .backgroundColor(sitePalette.primary.rgb)
//                                                    .cursor(Cursor.Pointer)
//                                                    .onClick {
//                                                        // Stop playback and reset states
//                                                        isPlayingAllHighlight = false
//                                                        currentAudioRefHighlight.value?.pause()
//                                                        currentlyPlayingIdHighlight = null
//                                                        currentlyPlayingIndexHighlight = null
//                                                        audioProgress = 0f
//                                                        currentAudioRefHighlight.value = null
//                                                    },
//                                                contentAlignment = Alignment.Center
//                                            ) {
//                                                FaPause(
//                                                    modifier = Modifier.color(Palette.White.rgbLight),
//                                                    size = IconSize.XXS
//                                                )
//                                            }
//                                        } else {
//                                            // Play All Highlights Button
//                                            Image(
//                                                src = Res.Images.play_all_button,
//                                                modifier = Modifier.onClick {
//                                                    if (getSentences.isNotEmpty()) {
//                                                        isPlayingAllHighlight = true // Enable "Play All" mode
//                                                        scope.launch {
//                                                            for ((index, sentenceData) in getSentences.withIndex()) {
//                                                                if (!isPlayingAllHighlight) {
//                                                                    // Exit playback if "Play All" is stopped
//                                                                    return@launch
//                                                                }
//
//                                                                val sessionId = sentenceData.sessionId
//                                                                currentlyPlayingIdHighlight = sessionId // Set the current session ID
//                                                                currentlyPlayingIndexHighlight = index // Update current index
//                                                                loadingSessionIdHighlight = sessionId // Show loading indicator
//
//                                                                // Load and play the current highlight
//                                                                playAllAudio.onloadeddata = {
//                                                                    loadingSessionIdHighlight = null // Remove loading indicator
//                                                                    playAllAudio.currentTime = sentenceData.startTime
//                                                                    playAllAudio.play()
//                                                                }
//                                                                playAllAudio.load()
//
//                                                                // Monitor progress and wait until the current highlight ends
//                                                                while (playAllAudio.currentTime < sentenceData.endTime) {
//                                                                    if (!isPlayingAllHighlight) {
//                                                                        playAllAudio.pause()
//                                                                        return@launch // Exit playback if stopped
//                                                                    }
//
//                                                                    audioProgress = ((playAllAudio.currentTime - sentenceData.startTime) /
//                                                                            (sentenceData.endTime - sentenceData.startTime)).toFloat()
//                                                                    delay(100) // Prevent tight looping
//                                                                }
//
//                                                                // Stop current highlight and reset progress
//                                                                playAllAudio.pause()
//                                                                audioProgress = 0f
//                                                            }
//
//                                                            // Reset state after playback ends
//                                                            isPlayingAllHighlight = false
//                                                            currentlyPlayingIdHighlight = null
//                                                            currentlyPlayingIndexHighlight = null
//                                                            audioProgress = 0f
//                                                        }
//                                                    }
//                                                }
//                                                    .size(27.px)
//                                            )
//                                        }
//                                    }
//
//                                    // Text Label for "Play All"
//                                    FieldTitleTextNormalSimple(
//                                        "Play All",
//                                        fontWeight = 500,
//                                        fontSize = 16.px,
//                                        fontFamily = Constants.IBM_FONT
//                                    )
//                                }

                            }

                            Column(
                                modifier = Modifier.fillMaxWidth().margin(topBottom = 10.px)
                                    .overflow(
                                        overflowY = Overflow.Auto,
                                        overflowX = Overflow.Hidden
                                    )
                            ) {

                                getSentences.forEachIndexed { index, sentenceData ->
                                    val sessionId = sentenceData.sessionId
//                                    val audio = remember { Audio("${Res.Audio.recording_Audio}${sentenceData.sessionId}") }
                                    var audioProgress by remember { mutableStateOf(0f) }

                                    var isPlayingAllHighlight by remember { mutableStateOf(false) }
                                    var currentlyPlayingIndexHighlight by remember { mutableStateOf<Int?>(null) }
                                    var currentlyPlayingIdHighlight by remember { mutableStateOf<String?>(null) }
                                    var loadingSessionIdHighlight by remember { mutableStateOf<String?>(null) }
                                    val currentAudioRefHighlight = remember { mutableStateOf<Audio?>(null) }


                                    // Add individual playing state for each audio
                                    var isThisPlaying by remember { mutableStateOf(false) }
//                                var selectedPlayersSentence by remember { mutableStateOf<Map<String, List<GetClubPlayerData>>>(emptyMap()) }

                                    scope.launch {
                                        val taggedPlayers = sentenceData.tagPlayers
                                        if (taggedPlayers?.isNotEmpty() == true) {
                                            taggedPlayers.forEach { player ->
                                                val filterPlayer =
                                                    players.filter { it.uid == player.toString() }
                                                if (filterPlayer.isNotEmpty()) {
                                                    selectedPlayers =
                                                        selectedPlayers.toMutableMap().apply {
                                                            put(
                                                                sentenceData.id,
                                                                filterPlayer
                                                            )
                                                        }
                                                }
                                            }
                                        }
                                    }

                                    LaunchedEffect(currentlyPlayingIdHighlight) {
                                        // Update individual playing state
                                        isThisPlaying = currentlyPlayingIdHighlight == sessionId

                                        if (currentlyPlayingIdHighlight == sessionId) {
                                            val audioPlay = currentAudioRefHighlight.value
                                            audioPlay?.apply {
                                                while (currentTime < sentenceData.endTime) {
                                                    audioProgress = ((currentTime - sentenceData.startTime) / (sentenceData.endTime - sentenceData.startTime)).toFloat()
                                                    delay(100)

                                                    if (currentTime >= sentenceData.endTime) {
                                                        // Stop playback once the sentence ends
                                                        pause()
                                                        currentlyPlayingIdHighlight = null
                                                        currentAudioRefHighlight.value = null
                                                        audioProgress = 0f
                                                        isThisPlaying = false // Reset playing state
                                                        break
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    if (sentenceData.keywords.isNotEmpty()) {
                                        Column(
                                            modifier = Modifier.margin(bottom = 10.px)
                                                .fillMaxWidth()
                                        ) {
                                            Row(
                                                modifier = Modifier.width(390.px)
                                                    .height(40.px)
                                                    .borderRadius(8.px)
                                                    .padding(8.px)
                                                    .gap(8.px)
                                                    .backgroundColor(sitePalette.searchBackground.rgb),
                                                verticalAlignment = Alignment.CenterVertically
                                            ) {
                                                Box(
                                                    modifier = Modifier.size(30.px)
                                                        .borderRadius(100.percent)
                                                        .backgroundColor(if (currentlyPlayingIdHighlight == sessionId) sitePalette.primary.rgb else Colors.Transparent)
                                                        .cursor(Cursor.Pointer)
                                                        .onClick {


                                                            isPlayingAllHighlight =
                                                                false  // Reset play all flag for individual play
                                                            if (currentlyPlayingIdHighlight == sessionId) {
                                                                // Stop current audio
                                                                getSentenceAudio.pause()
                                                                currentlyPlayingIdHighlight = null
                                                                currentlyPlayingIndexHighlight = null
                                                                currentAudioRefHighlight.value = null
                                                                audioProgress = 0f
                                                            } else {
                                                                // Play clicked audio
                                                                currentAudioRefHighlight.value?.pause()
                                                                loadingSessionIdHighlight = sessionId

                                                                getSentenceAudio.onloadeddata = {
                                                                    loadingSessionIdHighlight = null
                                                                    getSentenceAudio.currentTime =
                                                                        sentenceData.startTime
                                                                    getSentenceAudio.play()
                                                                    fullAudio.pause()
                                                                    playAllAudio.pause()
                                                                    currentlyPlayingIdHighlight = sessionId
                                                                    currentlyPlayingIndexHighlight = index
                                                                    getSentenceAudio.also {
                                                                        currentAudioRefHighlight.value = it
                                                                    }
                                                                }
                                                                getSentenceAudio.load()
                                                            }
                                                        },
                                                    contentAlignment = Alignment.Center
                                                ) {
                                                    if (loadingSessionIdHighlight == sessionId) {
                                                        ProgressIndicator(10.px)
                                                    } else if (currentlyPlayingIdHighlight == sessionId) {
                                                        FaPause(
                                                            modifier = Modifier.color(Palette.White.rgbLight),
                                                            size = IconSize.XXS
                                                        )
                                                    } else {
                                                        Image(
                                                            src = Res.Images.play_all_button,
                                                            modifier = Modifier.size(30.px)
                                                        )
                                                    }
                                                }
                                                SentenceAudioWaveform(
                                                    modifier = Modifier.fillMaxWidth(),
                                                    progress = audioProgress,
                                                    startTime = sentenceData.startTime,
                                                    endTime = sentenceData.endTime,
                                                    isPlaying = isThisPlaying,
                                                    onProgressChange = { newProgress ->
                                                        audioProgress = newProgress
                                                        currentAudioRefHighlight.value?.let { audio ->
                                                            audio.currentTime =
                                                                sentenceData.startTime + (sentenceData.endTime - sentenceData.startTime) * newProgress
                                                        }
                                                    }
                                                )
                                            }

                                            // Keywords Row
                                            Row(
                                                modifier = Modifier.fillMaxWidth()
                                                    .margin(topBottom = 10.px)
                                                    .flexWrap(FlexWrap.Wrap)
                                                    .overflow(
                                                        overflowY = Overflow.Auto,
                                                        overflowX = Overflow.Hidden
                                                    )
                                            ) {
                                                sentenceData.keywords.forEach { keyword ->
                                                    val keywordCategory = keyword.keywordCategory
                                                    val keywordText = keyword.text
                                                    Box {
                                                        Box(
                                                            modifier = Modifier.height(32.px)
                                                                .backgroundColor(
                                                                    when (keywordCategory) {
                                                                        "fitness" -> sitePalette.fitnessCategoryBackground.rgb
                                                                        "mental" -> sitePalette.mentalCategoryBackground.rgb
                                                                        "technical" -> sitePalette.technicalCategoryBackground.rgb
                                                                        "tactical" -> sitePalette.tacticalCategoryBackground.rgb
                                                                        "goalkeeper" -> sitePalette.goalKeeperCategoryBackground.rgb
                                                                        else -> sitePalette.textErrorColor.rgb
                                                                    }
                                                                )
                                                                .borderRadius(7.px)
                                                                .padding(
                                                                    leftRight = 8.px,
                                                                    topBottom = 4.px
                                                                )
                                                                .border(
                                                                    1.px, color =
                                                                    when (keywordCategory) {
                                                                        "fitness" -> sitePalette.fitnessCategoryBorder.rgb
                                                                        "mental" -> sitePalette.mentalCategoryBorder.rgb
                                                                        "technical" -> sitePalette.technicalCategoryBorder.rgb
                                                                        "tactical" -> sitePalette.tacticalCategoryBorder.rgb
                                                                        "goalkeeper" -> sitePalette.goalKeeperCategoryBorder.rgb
                                                                        else -> sitePalette.textErrorColor.rgb
                                                                    }, style = LineStyle.Solid
                                                                )
                                                                .margin(7.px),
                                                            contentAlignment = Alignment.Center
                                                        ) {
                                                            FieldTitleTextNormalSimple(keywordText)
                                                        }
                                                    }
                                                }
                                            }

                                            // Tag Player
                                            FieldTitleTextNormalSimple(
                                                "Tag Player",
                                                fontFamily = Constants.IBM_FONT,
                                                fontSize = 12.px,
                                                fontWeight = 600,
                                                marginBottom = 10.px,
                                                marginTop = 10.px
                                            )
                                            PlayerTokenSearch(
                                                sitePalette = sitePalette,
                                                breakpoint = breakpoint,
                                                players = players,
                                                selectedPlayersMap = selectedPlayers,
                                                onPlayerSelected = { sentenceId, player ->
                                                    selectedPlayers =
                                                        selectedPlayers.toMutableMap().apply {
                                                            put(
                                                                sentenceId,
                                                                (this[sentenceId]
                                                                    ?: emptyList()) + player
                                                            )
                                                        }
                                                },
                                                onPlayerRemoved = { sentenceId, player ->
                                                    selectedPlayers =
                                                        selectedPlayers.toMutableMap().apply {
                                                            put(
                                                                sentenceId,
                                                                (this[sentenceId]
                                                                    ?: emptyList()) - player
                                                            )
                                                        }
                                                },
                                                sentenceId = sentenceData.id
                                            )

                                            // Sentiment Row
                                            Row(
                                                modifier = Modifier.fillMaxWidth()
                                                    .margin(topBottom = 10.px),
                                                horizontalArrangement = Arrangement.SpaceBetween,
                                                verticalAlignment = Alignment.CenterVertically
                                            ) {
                                                Row {
                                                    FieldTitleTextNormalSimple(
                                                        text = "${getEmoji(sentenceData.emotions)} ${sentenceData.emotions}".replaceFirstChar {
                                                            if (it.isLowerCase()) it.titlecase() else it.toString()
                                                        },
                                                        fontSize = 12.px,
                                                        marginLeft = 3.px,
                                                        fontWeight = 500,
                                                        fontFamily = Constants.IBM_FONT
                                                    )
                                                    FieldTitleTextNormalSimple(
                                                        text = " . ",
                                                        fontSize = 12.px,
                                                        marginLeft = 3.px,
                                                        fontWeight = 500,
                                                        fontFamily = Constants.IBM_FONT
                                                    )
                                                    FieldTitleTextNormalSimple(
                                                        text = "${getEmoji(sentenceData.tone)} ${sentenceData.tone}".replaceFirstChar {
                                                            if (it.isLowerCase()) it.titlecase() else it.toString()
                                                        },
                                                        marginLeft = 3.px,
                                                        fontSize = 12.px,
                                                        fontWeight = 500,
                                                        fontFamily = Constants.IBM_FONT
                                                    )
                                                }
                                                Row {
                                                    if (expandedIndex == index) {
                                                        FaChevronUp(
                                                            modifier = Modifier.color(Colors.Gray)
                                                                .cursor(Cursor.Pointer)
                                                                .onClick {
                                                                    expandedIndex = -1
                                                                } // Collapse
                                                        )
                                                    } else {
                                                        FaChevronDown(
                                                            modifier = Modifier.color(Colors.Gray)
                                                                .cursor(Cursor.Pointer)
                                                                .onClick {
                                                                    expandedIndex = index
                                                                } // Expand this item
                                                        )
                                                    }

                                                }
                                            }

                                            // Expanded Content
                                            if (expandedIndex == index) {
                                                FieldTitleTextNormalSimple(
                                                    sentenceData.transcript,
                                                    fontFamily = Constants.IBM_FONT,
                                                    fontSize = 14.px,
                                                    marginBottom = 10.px,
                                                    color = Colors.Gray
                                                )
                                            }

                                            CustomDivider()
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            Input(
                type = InputType.File,
                attrs = {
                    id(Res.Id.fileInput)
                    style {
                        display(DisplayStyle.None)
                    }
                    onChange { event ->
                        val files = (event.target).files
                        if (files != null && files.length > 0) {
                            val file = files[0]
                            if (file != null) {
                                val reader = FileReader()
                                reader.onload = { e ->
                                    val result = e.target?.asDynamic()?.result as? String
                                    if (result != null) {

                                        uploadedVideoUrl = result
                                    }
                                }
                                reader.readAsDataURL(file)
                            }
                        }
                    }
                }
            )
        }
    } else {
        Box(
            modifier = Modifier
                .width(
                    if (breakpoint > Breakpoint.LG) 65.percent else if (breakpoint == Breakpoint.LG) 59.percent
                    else if (breakpoint >= Breakpoint.MD) 49.percent else 1360.px
                )
                .margin(topBottom = if (breakpoint <= Breakpoint.MD) 10.px else 0.px)
                .backgroundColor(sitePalette.modalBackgroundColor.rgb)
                .borderRadius(15.px)
                .overflow(
                    overflowY = Overflow.Hidden,
                    overflowX = Overflow.Auto
                )
                .boxShadow(
                    offsetY = 1.px,
                    blurRadius = 3.px,
                    color = sitePalette.boxShadowColor.rgb
                )
                .padding(
                    leftRight = if (breakpoint >= Breakpoint.LG) 30.px else 20.px,
                    topBottom = if (breakpoint >= Breakpoint.LG) 20.px else 10.px
                )
        ) {
            Column(
                modifier = Modifier.width(if (breakpoint <= Breakpoint.LG) 1360.px else 100.percent)
                    .fillMaxHeight()
            ) {
                Row(
                    modifier = Modifier.fillMaxWidth(),
                    verticalAlignment = Alignment.CenterVertically,
                    horizontalArrangement = Arrangement.SpaceBetween
                ) {
                    SectionTitle(sessionName.replaceFirstChar { if (it.isLowerCase()) it.titlecase() else it.toString() })
                    CommonButton(
                        text = "Upload Video",
                        icon = {
                            FaUpload(
                                size = IconSize.LG,
                                modifier = Modifier.color(sitePalette.primary.rgb)
                            )
                        },
                        width = 150.px,
                        height = 40.px,
                        borderColor = sitePalette.primary.rgb,
                        backgroundColor = Colors.Transparent,
                        textSpanColor = sitePalette.primary.rgb,
                        onCLick = {
                            val fileInput =
                                document.getElementById(Res.Id.fileInput) as HTMLInputElement
                            fileInput.click()
                        })
                }
                Row(
                    modifier = Modifier.fillMaxWidth().gap(36.px),
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    Row(verticalAlignment = Alignment.CenterVertically) {
//                    Image(
//                        src = Res.Images.coachPic,
//                        modifier = Modifier.size(30.px).margin(right = 8.px)
//                            .borderRadius(100.px).textAlign(TextAlign.Start)
//                            .border(
//                                width = 2.px,
//                                color = sitePalette.primary.rgb,
//                                style = LineStyle.Solid
//                            )
//                    )
                        FieldTitleTextNormalSimple(
                            text = "Coach:  ",
                            fontFamily = Constants.IBM_FONT,
                            fontSize = 16.px
                        )
                        FieldTitleTextNormalSimple(
                            text = sessionData.coachName,
                            fontWeight = 600,
                            fontFamily = Constants.IBM_FONT,
                            fontSize = 16.px
                        )
                    }

                    Row(
                        verticalAlignment = Alignment.CenterVertically
                    ) {
                        Image(src = Res.Images.timeCircle, modifier = Modifier.size(17.px))
                        CustomSpacer(5.px)
                        FieldTitleTextNormalSimple(
                            text = "Duration:  ",
                            fontFamily = Constants.IBM_FONT,
                            fontSize = 16.px
                        )
                        FieldTitleTextNormalSimple(
                            text = sessionData.recordTime.toLongOrNull()?.let {
                                (it / 1000).formatSessionMinutes()
                            } ?: "Invalid Time",
                            fontWeight = 600,
                            fontFamily = Constants.IBM_FONT,
                            fontSize = 16.px
                        )
                    }
                    Row(
                        verticalAlignment = Alignment.CenterVertically
                    ) {
                        Image(src = Res.Images.calender, modifier = Modifier.size(17.px))
                        CustomSpacer(5.px)
                        FieldTitleTextNormalSimple(
                            text = "Recorded:  ",
                            fontFamily = Constants.IBM_FONT,
                            fontSize = 16.px
                        )
                        FieldTitleTextNormalSimple(
                            text = "${sessionData.sessionDateTime.formatSessionDate()} ${sessionData.sessionDateTime.formatSessionHours()}",
                            fontWeight = 600,
                            fontFamily = Constants.IBM_FONT,
                            fontSize = 16.px
                        )
                    }
                }

                Row (modifier = Modifier.fillMaxWidth()
                    .height(200.px),
                    verticalAlignment = Alignment.CenterVertically,
                    horizontalArrangement = Arrangement.SpaceBetween){
                    FieldTitleTextNormalSimple(
                        text = "No data found for this session",
                        color = sitePalette.textFieldTextColor.rgb,
                        fontSize = 16.px,
                        textAlign = TextAlign.Center,
                        width = 100.percent
                    )
                }
            }

        }
    }
}



    val emojiMap = mapOf(
        "sad" to "\uD83D\uDE25",       // 😥
        "sadness" to "\uD83D\uDE25",       // 😥
        "frustrated" to "\uD83D\uDE21", // 😡
        "frustration" to "\uD83D\uDE21", // 😡
        "polite" to "\uD83D\uDE0C",    // 😌
        "impolite" to "\uD83D\uDE20",  // 😠
        "sympathetic" to "\uD83E\uDD17", // 🤗
        "satisfied" to "\uD83D\uDE0A", // 😊
        "excited" to "\uD83E\uDD29" ,   // 🤩
        "Excited" to "\uD83E\uDD29" ,   // 🤩
        "joy" to "\uD83E\uDD29" ,   // 🤩
        "positive" to "\uD83D\uDE0A", // 😊
        "negative" to "\uD83D\uDE41", // 🙁
        "neutral" to "\uD83D\uDE10",   // 😐
        "Neutral" to "\uD83D\uDE10",   // 😐
        "fear" to "\uD83D\uDE31",   //
        "informative" to "\uD83E\uDD13",   //
    )
    fun getEmoji(className: String): String {
        return emojiMap[className.lowercase()] ?: "\uD83D\uDE42" // Default to  if no match
    }
