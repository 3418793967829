package org.example.zone360.pages.authentication

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.maxWidth
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import kotlinx.browser.document
import kotlinx.coroutines.launch
import login
import org.example.zone360.components.CommonButton
import org.example.zone360.components.InputField
import org.example.zone360.componentss.FieldTitleText
import org.example.zone360.componentss.FieldTitleTextNormal
import org.example.zone360.componentss.FieldTitleTextNormalSimple
import org.example.zone360.theme.Palette
import org.example.zone360.theme.toSitePalette
import org.example.zone360.utils.AppGlobal
import org.example.zone360.utils.Res
import org.example.zone360.utils.isValidEmail
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.css.px
import org.w3c.dom.HTMLInputElement

@Page("/login")
@Composable
fun LoginPage() {
    val scope = rememberCoroutineScope()
    val context = rememberPageContext()
    val breakpoint = rememberBreakpoint()
    val sitePalette = ColorMode.current.toSitePalette()

    var showPassword by remember { mutableStateOf(false) }
    var isLoading by remember { mutableStateOf(false) }
    var isError by remember { mutableStateOf(false) }
    var serverError by remember { mutableStateOf(" ") }

    Box(
        Modifier.fillMaxSize().color(sitePalette.bodyBackground.rgb),
        contentAlignment = Alignment.Center
    ) {

        Box(
            Modifier.color(Colors.Black).backgroundColor(sitePalette.modalBackgroundColor.rgb)
                .maxWidth(400.px)
                .borderRadius(r = 6.px), contentAlignment = Alignment.Center
        ) {
            Column(
                modifier = Modifier.padding(topBottom = 40.px, leftRight = 30.px),
            ) {

                FieldTitleText(text = "Login")
                FieldTitleTextNormal(text = "Email") {}
                InputField(
                    errorText = "Please enter an email (e.g. name@domain.com).",
                    inputType = InputType.Email,
                    placeHolder = "",
                    id = Res.Id.emailInput,
                    isError = isError,
                    marginBottom = 3.px,
                    disabled = true,
                )
                Row(
                    modifier = Modifier.margin(top = 25.px, bottom = 5.px).fillMaxWidth()
                        .color(Colors.Black),
                    horizontalArrangement = Arrangement.SpaceBetween
                ) {
                    FieldTitleTextNormalSimple(
                        "Password",
                        onClick = {},
                        color = sitePalette.textFieldTextColor.rgb
                    )
                    Spacer()
                    FieldTitleTextNormalSimple(if (showPassword) "Hide" else "Show", onClick = {
                        showPassword = !showPassword
                    }, isClickable = true, color = sitePalette.primary.rgb)
                }

                InputField(
                    errorText = "Please enter a password",
                    inputType = if (showPassword) InputType.Text else InputType.Password,
                    placeHolder = "",
                    id = Res.Id.passwordInput,
                    isError = isError,
                    marginBottom = 3.px,
                    disabled = true,
                )

//                FieldTitleTextNormal(
//                    isClickable = true,
//                    text = "Forgot password?",
//                    color = sitePalette.primary.rgb,
//                    fontSize = 11.px, onClick = {
//
//                    }
//                )
                Box(Modifier.margin(topBottom = 10.px)) { }
                CommonButton(
                    byPassEmailVerification = true,
                    text = "Log in",
                    isLoading = isLoading,
                    onCLick = {
                        scope.launch {
                            val emailInput =
                                (document.getElementById(Res.Id.emailInput) as HTMLInputElement).value
                            val passwordInput =
                                (document.getElementById(Res.Id.passwordInput) as HTMLInputElement).value
                            if (emailInput.isEmpty() ) {
                                isError = true
                                serverError = "Please enter an email"
                            } else if (!isValidEmail(email = emailInput)) {
                                isError = true
                                serverError = "Invalid email format"
                            } else if (passwordInput.isEmpty()) {
                                isError = true
                                serverError = "Please enter a password"
                            } else {
                                isLoading = true
                                isError = false

                                val user = login(
                                    email = emailInput, password = passwordInput
                                )
                                println("user: ${user.status}")
                                isLoading = false
                                if (user.status == "success" && user.role == "admin") {
                                    AppGlobal.userData = user
                                    println("userData: ${AppGlobal.userData}")
                                    context.router.navigateTo("/home")
                                } else {
                                    isError = true
                                    serverError = "Login Failed"
                                }
                            }
                        }
                    }
                )

                // Display server error message
                Box(
                    modifier = Modifier.margin(topBottom = 10.px).fillMaxWidth()
                        .align(Alignment.CenterHorizontally),
                    contentAlignment = Alignment.Center
                ) {
                    FieldTitleTextNormalSimple(
                        text = serverError,
                        fontSize = 13.px,
                        color = sitePalette.textErrorColor.rgb, onClick = {}
                    )
                }

                // Display version
                Box(
                    modifier = Modifier.margin(bottom = 0.px).fillMaxWidth()
                        .align(Alignment.CenterHorizontally),
                    contentAlignment = Alignment.Center
                ) {
                    FieldTitleTextNormalSimple(
                        text = "Version 1.0.8",
                        fontSize = 11.px,
                        color = Palette.EditTextBorder.rgbLight, onClick = {}
                    )
                }
            }
        }
    }
}




