package org.example.zone360.pages.dashboard

import RecommendationPopup
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import com.varabyte.kobweb.compose.css.CSSLengthNumericValue
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxHeight
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.flexWrap
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.id
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.overflow
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import getClubCoaches
import getClubKeyword
import getClubPerformance
import getClubSession
import getPlayerAggregate
import kotlinx.browser.localStorage
import kotlinx.browser.window
import kotlinx.coroutines.launch
import org.example.zone360.components.openComposeMailWindow
import org.example.zone360.models.GetClubCoachesData
import org.example.zone360.models.GetClubKeywordData
import org.example.zone360.models.GetClubPerformanceData
import org.example.zone360.models.GetPlayerData
import org.example.zone360.models.SessionData
import org.example.zone360.pages.dashboard.clubPerformance.ClubPerformance
import org.example.zone360.pages.dashboard.coaches.AddNewCoach
import org.example.zone360.pages.dashboard.coaches.CoachesLayout
import org.example.zone360.pages.dashboard.coaches.openRecommendationWindow
import org.example.zone360.pages.dashboard.emphasisBreakdown.EmphasisBreakdown
import org.example.zone360.pages.dashboard.infoLayout.InfoLayout
import org.example.zone360.pages.dashboard.keywordUsage.KeywordUsage
import org.example.zone360.pages.dashboard.playerAssessment.AddNewPlayer
import org.example.zone360.pages.dashboard.playerAssessment.DeletePlayer
import org.example.zone360.pages.dashboard.playerAssessment.PlayerAssessment
import org.example.zone360.pages.dashboard.playerAssessment.PlayerRecommendationPopup
import org.example.zone360.pages.dashboard.recentAssessment.RecentAssessment
import org.example.zone360.pages.dashboard.recentHighlight.RecentHighlight
import org.example.zone360.sections.DashboardHeaderSection
import org.example.zone360.theme.toSitePalette
import org.example.zone360.utils.AppGlobal
import org.example.zone360.utils.Constants
import org.example.zone360.utils.Res
import org.example.zone360.utils.SelectedData
import org.example.zone360.utils.SelectedLanguage
import org.jetbrains.compose.web.css.CSSSizeValue
import org.jetbrains.compose.web.css.CSSUnit
import org.jetbrains.compose.web.css.FlexWrap
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px

@Page("/home")
@Composable
fun DashboardPage() {
    val ctx = rememberPageContext()
    val scope = rememberCoroutineScope()
    val clubCoachesList = remember { mutableStateListOf<GetClubCoachesData>() }
    val clubPlayersList = remember { mutableStateListOf<GetPlayerData>() }
    val keywordResponse = remember { mutableStateListOf<GetClubKeywordData>() }
    var performanceResponse by remember { mutableStateOf(GetClubPerformanceData()) }
    val clubSessionList = remember { mutableStateListOf<SessionData>() }
    val playerSessionList = remember { mutableStateListOf<SessionData>() }
    var selectedCoach by remember { mutableStateOf(GetClubCoachesData()) }
    var selectedPlayer by remember { mutableStateOf(GetPlayerData()) }
    val breakpoint = rememberBreakpoint()
    val sitePalette = ColorMode.current.toSitePalette()
    var selectedLanguage by remember {
        mutableStateOf(
            when (localStorage.getItem("selectedLanguage")) {
                "it" -> SelectedLanguage.Italian
                else -> SelectedLanguage.English
            }
        )
    }
    var selectedDataType by remember {
        mutableStateOf(
            when (localStorage.getItem("selectedDataType")) {
                "match" -> SelectedData.Matches
                else -> SelectedData.Training
            }
        )
    }
    var isRecommendationPopupVisible by remember { mutableStateOf(false) }
    var isPlayerRecommendationVisible by remember { mutableStateOf(false) }
    var openNewWindow by remember { mutableStateOf(false) }
    var isNewPlayerPopup by remember { mutableStateOf(false) }
    var isNewCoachPopup by remember { mutableStateOf(false) }
    var deletePlayerPopup by remember { mutableStateOf(false) }
    var isLoading by remember { mutableStateOf(false) }
    var languageType by remember { mutableStateOf(SelectedLanguage.English.name) }
    var sessionDataType by remember { mutableStateOf(SelectedData.Training.name) }

    val data = localStorage.getItem("userData")
    if (data == null) {
        ctx.router.navigateTo("/login")
        return
    }

    if (isRecommendationPopupVisible) {
        println("selectedCoach1: $selectedCoach")
        RecommendationPopup(
            onDialogDismiss = {
                isRecommendationPopupVisible = false
            },
            breakpoint = breakpoint,
            modalNumber = 1,
            data = selectedCoach,
            language = languageType,
            sessionType = sessionDataType

        )
//        openRecommendationWindow(
//            data = selectedCoach,
//            language = languageType,
//            sessionType = sessionDataType
//        )
         
    }
    if (isPlayerRecommendationVisible) {
        PlayerRecommendationPopup(
            onDialogDismiss = {
                isPlayerRecommendationVisible = false
            },
            breakpoint = breakpoint,
            modalNumber = 2,
            language = languageType,
            data = selectedPlayer,
        )
    }
    if (isNewPlayerPopup) {
        AddNewPlayer(
            onDialogDismiss = {
                isNewPlayerPopup = false
                scope.launch {
                    val updatedPlayers = getPlayerAggregate(
                        organization = AppGlobal.userData.organization,
                        domainType = "soccer",
                        language = languageType,

                        )
                    clubPlayersList.clear()
                    clubPlayersList.addAll(updatedPlayers.data)
                }
            },
            breakpoint = breakpoint,
            modalNumber = 1,
            toastMessageCall = { message ->
                println("Toast message: $message")
            }
        )

    }
    if (deletePlayerPopup) {
        DeletePlayer(
            onDialogDismiss = {
                deletePlayerPopup = false
                scope.launch {
                    val deletePlayers = getPlayerAggregate(
                        organization = AppGlobal.userData.organization,
                        domainType = "soccer",
                        language = languageType,

                        )
                    clubPlayersList.clear()
                    clubPlayersList.addAll(deletePlayers.data)
                }
            },
            breakpoint = breakpoint,
            modalNumber = 3,
            toastMessageCall = { message ->
                println("Toast message: $message")
            },
        )
    }
    if (isNewCoachPopup) {
        AddNewCoach(
            onDialogDismiss = {
                isNewCoachPopup = false
                scope.launch {
                    val updatedCoach = getClubCoaches(
                        organization = AppGlobal.userData.organization,
                        domainType = "soccer",
                        language = languageType,
                        sessionType = sessionDataType
                        )
                    clubCoachesList.clear()
                    clubCoachesList.addAll(updatedCoach.data)

                }
            },
            breakpoint = breakpoint,
            modalNumber = 1,
            toastMessageCall = { message ->
                println("Toast message: $message")
            }
        )
    }
//   if (openNewWindow){
//       openComposeMailWindow()
//   }

    suspend fun fetchData(language: String, sessionType: String) {
        try {
                isLoading = true
           val performanceResponses = getClubPerformance(
                organization = AppGlobal.userData.organization,
                domainType = "soccer",
                language = language,
                sessionType = sessionType
            )
            performanceResponse = performanceResponses.data
//
             val keywordResponses = getClubKeyword(
                organization = AppGlobal.userData.organization,
                domainType = "soccer",
                sessionType = sessionType,
                language = language,
                keyword = "",
                getSentences = false
             )
            keywordResponse.clear()
            keywordResponse.addAll(keywordResponses.data)

//
            val user = getClubCoaches(
                AppGlobal.userData.organization,
                "soccer",
                sessionType,
                language
            )
            user.data.forEach { item ->
                val efficiency = if (item.speakingTime > 0) {
                    item.totalKeywords / item.speakingTime
                } else {
                    0.0
                }
                item.efficiency = (efficiency * 100)
            }
            clubCoachesList.clear()
            clubCoachesList.addAll(user.data)
//
            val sessionUser = getClubSession(
                organization = AppGlobal.userData.organization,
                sessionType = sessionType,
                domainType = "soccer",
                language = language
            )
            val sortedData = sessionUser.data.sortedByDescending { sessionData ->
                val parts = sessionData.sessionId.removeSuffix(".aac").split("_")
                parts.last()
            }
            clubSessionList.clear()
            clubSessionList.addAll(sortedData)

        } catch (e: Exception) {
            println("Error fetching data: $e")
        } finally {
            isLoading = false
        }
    }

    LaunchedEffect(selectedDataType, selectedLanguage) {
        val languageCode = when (selectedLanguage) {
            SelectedLanguage.English -> "en"
            SelectedLanguage.Italian -> "it"
        }
        languageType = languageCode

        localStorage.setItem("selectedLanguage", languageCode)
        println("selectedLanguage: $selectedLanguage")
        val sessionType = when (selectedDataType) {
            SelectedData.Training -> "training"
            SelectedData.Matches -> "match"
        }
        sessionDataType = sessionType

        localStorage.setItem("selectedDataType", sessionType)
        println("sessionType: $sessionType")

            fetchData(languageCode, sessionType)
    }

    LaunchedEffect(Unit) {

        val playerUser = getClubSession(
            organization = AppGlobal.userData.organization,
            sessionType = "player assessment",
            domainType = "soccer",
            language = "en"
        )
        val sortedData = playerUser.data.sortedByDescending { sessionData ->
            val parts = sessionData.sessionId.removeSuffix(".aac").split("_")
            parts.last()
        }
        playerSessionList.clear()
        playerSessionList.addAll(sortedData)

    }

    LaunchedEffect(Unit) {

        //
        val playerData = getPlayerAggregate(
            organization = AppGlobal.userData.organization,
            domainType = "soccer",
            language = "en",
        )
        clubPlayersList.clear()
        clubPlayersList.addAll(playerData.data)

    }


    Box(Modifier.fillMaxSize()) {
        Column(
            Modifier.fillMaxSize().backgroundColor(sitePalette.bodyBackground.rgb)
                .overflow(overflowX = Overflow.Hidden, overflowY = Overflow.Auto)
                .id(Res.Id.dashboradPage)
        ) {
            DashboardHeaderSection()
            Column(
                modifier = Modifier.fillMaxHeight()
                        .fillMaxWidth(if (window.screen.width > 1800 )85.percent else 100.percent )
                    .padding(leftRight = if (breakpoint >= Breakpoint.LG) 60.px else 20.px)
                    .align(Alignment.CenterHorizontally),
                horizontalAlignment = Alignment.CenterHorizontally
            ) {
                Row(
                    modifier = Modifier.fillMaxWidth().margin(top = 10.px, bottom = 10.px),
                    verticalAlignment = Alignment.CenterVertically,
                ) {
                    SpanText(
                        "Dashboard", modifier = Modifier
                            .fontFamily(Constants.FONT_FAMILY)
                            .fontWeight(700)
                            .fontSize(26.px)
                            .margin(right = 20.px)
                    )
                    Row(
                        modifier = Modifier.width(188.px)
                            .height(42.px)
                            .borderRadius(6.px)
                            .backgroundColor(sitePalette.modalBackgroundColor.rgb)
                            .padding(leftRight = 5.px, topBottom = 4.px)
                            .border(
                                width = 1.px,
                                style = LineStyle.Solid,
                                color = Colors.LightGray
                            ),
                        verticalAlignment = Alignment.CenterVertically
                    ) {
                        SelectedBox(text = "Training",
                            breakpoint = breakpoint,
                            width = 87.px,
                            height = 34.px,
                            fontSize = 16.px,
                            fontWeight = 500,
                            isSelected = selectedDataType == SelectedData.Training,
                            onClick = {
                                selectedDataType = SelectedData.Training
                            })
                        SelectedBox(text = "Matches",
                            breakpoint = breakpoint,
                            width = 87.px,
                            height = 34.px,
                            fontSize = 16.px,
                            fontWeight = 500,
                            isSelected = selectedDataType == SelectedData.Matches,
                            onClick = {
                                selectedDataType = SelectedData.Matches
                            })

                    }
                    Spacer()
//                    SelectedBox(
//                        text = "English",
//                        breakpoint = breakpoint,
//                        isSelected = selectedLanguage == SelectedLanguage.English,
//                        onClick = { selectedLanguage = SelectedLanguage.English
//                        }
//                    )
//                    SelectedBox(
//                        text = "Italian",
//                        breakpoint = breakpoint,
//                        isSelected = selectedLanguage == SelectedLanguage.Italian,
//                        onClick = { selectedLanguage = SelectedLanguage.Italian }
//                    )
                }
                InfoLayout(
                    clubCoachesList, openNewWindow = { openNewWindow = true },
                    breakpoint
                )
                Row(
                    modifier = Modifier.fillMaxWidth().flexWrap(FlexWrap.Wrap)
                        .margin(topBottom = 10.px),
                    verticalAlignment = Alignment.CenterVertically,
                    horizontalArrangement = Arrangement.SpaceBetween
                ) {
                     ClubPerformance(performanceResponse, breakpoint, selectedLanguage, isLoading )
                    EmphasisBreakdown(keywordResponse , selectedLanguage, breakpoint, isLoading)
                    }
                Row(
                    modifier = Modifier.fillMaxWidth().flexWrap(FlexWrap.Wrap)
                        .margin(topBottom = 10.px),
                    verticalAlignment = Alignment.CenterVertically,
                    horizontalArrangement = Arrangement.SpaceBetween
                ) {
//
                        KeywordUsage(keywordResponse, selectedLanguage)
                }
                Row(
                    modifier = Modifier.fillMaxWidth().margin(topBottom = 10.px)
                        .flexWrap(FlexWrap.Wrap),
                    verticalAlignment = Alignment.CenterVertically,
                    horizontalArrangement = Arrangement.SpaceBetween
                ) {
                    CoachesLayout(clubCoachesList,
                        breakpoint,
                        onRecommendationClick = { data ->
                        selectedCoach = data
                        isRecommendationPopupVisible = true
                        println("selectedCoach: $selectedCoach") },
                        addNewCoach = { isNewCoachPopup = true }
                    )

                    RecentHighlight(clubSessionList, breakpoint)
//                    }


                }
                Row(
                    modifier = Modifier.fillMaxWidth().margin(topBottom = 10.px)
                        .flexWrap(FlexWrap.Wrap),
                    verticalAlignment = Alignment.CenterVertically,
                    horizontalArrangement = Arrangement.SpaceBetween
                ) {
                    PlayerAssessment(
                        data = clubPlayersList,
                        breakpoint,
                        onRecommendationClick = { data ->
                            selectedPlayer = data
                            isPlayerRecommendationVisible = true
                            println("selectedPlayer: $selectedPlayer")
                        },
                        addNewPlayer = { isNewPlayerPopup = true },
                        deletePlayer = { deletePlayerPopup = true }
                    )
//                    }
                    RecentAssessment(playerSessionList, breakpoint)

                }
            }
        }
    }
}

@Composable
fun SelectedBox(
    text: String,
    breakpoint: Breakpoint,
    width: CSSLengthNumericValue = 41.px,
    height: CSSLengthNumericValue = 32.px,
    fontWeight: Int = 400,
    fontSize: CSSLengthNumericValue? = null,
    isSelected: Boolean,
    isSelectedColor: Color? = null,
    marginRight: CSSLengthNumericValue = 0.px,
    onClick: () -> Unit
) {
    val sitePalette = ColorMode.current.toSitePalette()
    Box(
        modifier = Modifier
            .height(height)
            .width(width)
            .backgroundColor(
                if (isSelected) isSelectedColor ?: sitePalette.primary.rgb
                else sitePalette.modalBackgroundColor.rgb
            )
            .borderRadius(5.px)
            .onClick { onClick() }
            .cursor(Cursor.Pointer)
            .padding(10.px)
            .margin(right = marginRight),
        contentAlignment = Alignment.Center
    ) {
        SpanText(
            text,
            modifier = Modifier
                .fontFamily(Constants.FONT_FAMILY)
                .fontWeight(fontWeight)
                .color(
                    if (isSelected) sitePalette.modalBackgroundColor.rgb
                    else sitePalette.textFieldSupportiveTextColor.rgb
                )
                .fontSize(fontSize ?: 13.px)
        )
    }
}

@Composable
fun SectionTitle(
    text: String,
    bottom: CSSSizeValue<CSSUnit.px> = 15.px,
    top: CSSSizeValue<CSSUnit.px> = 0.px,
    right: CSSSizeValue<CSSUnit.px> = 0.px,
    left: CSSSizeValue<CSSUnit.px> = 0.px,
) {
    val breakpoint = rememberBreakpoint()
    val sitePalette = ColorMode.current.toSitePalette()
    SpanText(
        text, modifier = Modifier
            .fontFamily(Constants.FONT_FAMILY)
            .fontWeight(600)
            .color(sitePalette.titleColor.rgb)
            .fontSize(if (breakpoint >= Breakpoint.LG) 18.px else 16.px)
            .margin(bottom = bottom, top = top, right = right, left = left)
    )
}
