package org.example.zone360.pages.coachDetails

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxHeight
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.flexWrap
import com.varabyte.kobweb.compose.ui.modifiers.id
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.overflow
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.icons.fa.FaArrowLeft
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import getClubCoaches
import getSingleCoach
import kotlinx.browser.localStorage
import kotlinx.browser.window
import org.example.zone360.componentss.FieldTitleTextNormalSimple
import org.example.zone360.models.GetClubCoachesData
import org.example.zone360.models.GetClubPlayerData
import org.example.zone360.models.GetSessionsData
import org.example.zone360.models.GetSingleCoachData
import org.example.zone360.pages.coachDetails.coachInfo.CoachInfo
import org.example.zone360.pages.coachDetails.coachSession.CoachesSession
import org.example.zone360.pages.coachDetails.sessionAllDetails.SessionAllDetails
import org.example.zone360.sections.DashboardHeaderSection
import org.example.zone360.theme.toSitePalette
import org.example.zone360.utils.AppGlobal
import org.example.zone360.utils.Res
import org.example.zone360.utils.SelectedData
import org.example.zone360.utils.SelectedLanguage
import org.jetbrains.compose.web.css.FlexWrap
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px

@Page("/coach-details")
@Composable
fun CoachDetails(){
    val ctx = rememberPageContext()
    val scope = rememberCoroutineScope()
    val breakpoint = rememberBreakpoint()
    val sitePalette = ColorMode.current.toSitePalette()
    var isLoading by remember { mutableStateOf(false) }
//
    val params = ctx.route.params
    val userId = params["userId"] ?: ""


//
    var languageType by remember { mutableStateOf(SelectedLanguage.English.name) }
    var sessionDataType by remember { mutableStateOf(SelectedData.Training.name) }

//
    var selectedLanguage by remember {
        mutableStateOf(
            when (localStorage.getItem("selectedLanguage")) {
                "it" -> SelectedLanguage.Italian
                else -> SelectedLanguage.English
            }
        )
    }
    var selectedDataType by remember {
        mutableStateOf(
            when (localStorage.getItem("selectedDataType")) {
                "match" -> SelectedData.Matches
                else -> SelectedData.Training
            }
        )
    }

    val clubCoachesList = remember { mutableStateListOf<GetClubCoachesData>() }

    var selectedSession by remember { mutableStateOf<GetSessionsData?>(null) }

    suspend fun fetchData(language: String, sessionType: String) {
        try {
            val response = getClubCoaches(
                AppGlobal.userData.organization,
                "soccer",
                sessionType,
                language
            )
            clubCoachesList.clear()
            clubCoachesList.addAll(response.data)

//            val res = getSingleCoach(
//                organization = AppGlobal.userData.organization,
//                domainType = "soccer",
//                language = language,
//                sessionType = sessionType,
//                userID =  userId)

        } catch (e: Exception) {
            println("Error fetching data: $e")
        } finally {
            isLoading = false
            println("success")
        }
    }
    LaunchedEffect(selectedDataType, selectedLanguage) {
        val languageCode = when (selectedLanguage) {
            SelectedLanguage.English -> "en"
            SelectedLanguage.Italian -> "it"
        }
        languageType = languageCode

        localStorage.setItem("selectedLanguage", languageCode)
        println("selectedLanguage: $selectedLanguage")
        val sessionType = when (selectedDataType) {
            SelectedData.Training -> "training"
            SelectedData.Matches -> "match"
        }
        sessionDataType = sessionType

        localStorage.setItem("selectedDataType", sessionType)
        println("sessionType: $sessionType")

        fetchData(languageCode, sessionType)
    }
    Box(Modifier.fillMaxSize()) {
        Column(
            Modifier.fillMaxSize().backgroundColor(sitePalette.bodyBackground.rgb)
                .overflow(overflowX = Overflow.Hidden, overflowY = Overflow.Auto)
                .id(Res.Id.coachesPage)
        ) {
            DashboardHeaderSection()
        Column(
            modifier = Modifier.fillMaxHeight().fillMaxWidth(if (window.screen.width > 1800 )85.percent else 100.percent )
                .padding(leftRight = if (breakpoint >= Breakpoint.LG) 60.px else 20.px)
                .align(Alignment.CenterHorizontally),
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            Row(
                modifier = Modifier.fillMaxWidth().margin(top = 30.px, bottom = 10.px),
                verticalAlignment = Alignment.CenterVertically,
            ) {
                FaArrowLeft(
                    modifier = Modifier.onClick {
//                        window.open(
//                            url = "/home"
//                        )
                        if (window.history.length > 1) {
                            window.history.back()
                        } else {
                            ctx.router.navigateTo("/home")
                        }

                    }
                        .cursor(Cursor.Pointer)
                )
                FieldTitleTextNormalSimple(
                    text = "Coach Details",
                    fontWeight = 500,
                    fontSize = 16.px,
                    marginLeft = 15.px
                )
            }
            CoachInfo(
                breakpoint = breakpoint,
                sitePalette = sitePalette,
                userId = userId,
                selectedSession = selectedDataType)
          Row (modifier = Modifier.fillMaxWidth().margin(topBottom = 10.px).flexWrap(FlexWrap.Wrap),
              horizontalArrangement = Arrangement.SpaceBetween){
              CoachesSession(
                  breakpoint = breakpoint,
                  sitePalette = sitePalette,
                  userId = userId,
                  sessionDataType = selectedDataType,
                  onSessionTypeChange = { newType ->
                      selectedDataType = newType
                  },
                  onSessionSelect = { session ->
                      selectedSession = session
                  }
              )
              selectedSession?.let { session ->
                  SessionAllDetails(
                      breakpoint = breakpoint,
                      sitePalette = sitePalette,
                      sessionData = session,
                  )
              }
          }

        }

        }
    }
}