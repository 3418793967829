package org.example.zone360.models

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class GetSessionsResponse(
    val data: List<GetSessionsData>
)
@Serializable
data class GetSessionsData(
    @SerialName("_id")
    val id: String = "",
    @SerialName("_rev")
    val rev: String = "",
    val audioPath: String = "",
    val coachName: String = "",
    val domainType: String = "",
    val language: String = "",
    val organization: String = "",
    val recordTime: String = "",
    val sessionDateTime: String = "",
    @SerialName("sessionID")
    val sessionId: String = "",
    val sessionType: String = "",
    val status: String = "",
    val statusUrl: String = "",
    val type: String = "",
    @SerialName("userID")
    val userId: String = "",
    @SerialName("playerID")
    val playerId: String = "",
    val playerName: String = "",
    val tagPlayers: List<String>?=emptyList(),
    val sessionData: String = "",
    val speakingTime: Double = 0.0,
    val summaryRevision: Int = 1,
    val text: String = "",
    val totalKeywords: Int = 1,
    val wordCount: Int = 1,
)


@Serializable
data class SessionDataDetailsResponse(
    val response: SessionDataDetailsData,
)
@Serializable
data class SessionDataDetailsData(
    @SerialName("emotion analysis")
    val emotionAnalysis: EmotionAnalysis,
    @SerialName("keys number")
    val keysNumber: Long,
    @SerialName("keyword extraction")
    val keywordExtraction: KeywordExtraction,
    @SerialName("sentiment analysis")
    val sentimentAnalysis: SentimentAnalysis,
    val summary: String,
    @SerialName("tone analysis")
    val toneAnalysis: ToneAnalysis,
)
@Serializable
data class EmotionAnalysis(
    @SerialName("emotion_predictions")
    val emotionPredictions: List<EmotionPrediction>,
    @SerialName("producer_id")
    val producerId: ProducerId,
)
@Serializable

data class EmotionPrediction(
    val emotion: Emotion,
    @SerialName("emotion_mentions")
    val emotionMentions: List<EmotionMention>,
    val target: String,
)
@Serializable

data class Emotion(
    val anger: Double,
    val disgust: Double,
    val fear: Double,
    val joy: Double,
    val sadness: Double,
)
@Serializable

data class EmotionMention(
    val emotion: Emotion2,
    val span: Span,
)
@Serializable

data class Emotion2(
    val anger: Double,
    val disgust: Double,
    val fear: Double,
    val joy: Double,
    val sadness: Double,
)
@Serializable

data class Span(
    val begin: Long,
    val end: Long,
    val text: String,
)
@Serializable

data class ProducerId(
    val name: String,
    val version: String,
)
@Serializable

data class KeywordExtraction(
    @SerialName("producer_id")
    val producerId: ProducerId2,
    val views: List<View>,
)
@Serializable

data class ProducerId2(
    val name: String,
    val version: String,
)
@Serializable

data class View(
    val name: String,
    val properties: List<Property>,
)
@Serializable

data class Property(
    @SerialName("aql_property")
    val aqlProperty: AqlProperty,
)
@Serializable

data class AqlProperty(
    val label: String,
    val match: Match,
)
@Serializable

data class Match(
    val begin: Long,
    val end: Long,
    val text: String,
)
@Serializable
data class SentimentAnalysis(
    @SerialName("document_sentiment")
    val documentSentiment: DocumentSentiment,
    @SerialName("producer_id")
    val producerId: ProducerId3,
    @SerialName("targeted_sentiments")
    val targetedSentiments: TargetedSentiments,
)
@Serializable

data class DocumentSentiment(
    val label: String,
    val mixed: Boolean,
    val score: Double,
    @SerialName("sentiment_mentions")
    val sentimentMentions: List<SentimentMention>,
)
@Serializable

data class SentimentMention(
    val sentimentprob: Sentimentprob,
    val span: Span2,
)
@Serializable

data class Sentimentprob(
    val negative: Double,
    val neutral: Double,
    val positive: Double,
)
@Serializable

data class Span2(
    val begin: Long,
    val end: Long,
    val text: String,
)
@Serializable

data class ProducerId3(
    val name: String,
    val version: String,
)
@Serializable

data class TargetedSentiments(
    @SerialName("producer_id")
    val producerId: ProducerId4,
)
@Serializable

data class ProducerId4(
    val name: String,
    val version: String,
)
@Serializable

data class ToneAnalysis(
    val classes: List<Class>,
    @SerialName("producer_id")
    val producerId: ProducerId5,
)
@Serializable

data class Class(
    @SerialName("class_name")
    val className: String,
    val confidence: Double,
)
@Serializable

data class ProducerId5(
    val name: String,
    val version: String,
)



