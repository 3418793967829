package org.example.zone360.pages.players_Details.coachesRecommendation

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.boxShadow
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxHeight
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.maxHeight
import com.varabyte.kobweb.compose.ui.modifiers.overflow
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import getSinglePlayer
import kotlinx.coroutines.launch
import org.example.zone360.componentss.FieldTitleTextNormalSimple
import org.example.zone360.models.GetSinglePlayerData
import org.example.zone360.pages.dashboard.SectionTitle
import org.example.zone360.theme.SitePalette
import org.example.zone360.utils.Constants
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px

@Composable
fun CoachRecommendation(breakpoint: Breakpoint,
                        sitePalette: SitePalette,
                        userId: String) {

    val scope = rememberCoroutineScope()
    var getPlayerInfo by remember { mutableStateOf(GetSinglePlayerData()) }
    scope.launch {
        val getPlayerResponse = getSinglePlayer(
            domainType = "soccer",
            language = "en",
            userID = userId
        )
        getPlayerInfo = getPlayerResponse.response}
    Box(
        modifier = Modifier
            .maxHeight(400.px)
            .fillMaxWidth()
            .backgroundColor(sitePalette.modalBackgroundColor.rgb)
            .borderRadius(15.px)
            .overflow(
                overflowY = Overflow.Hidden,
                overflowX = Overflow.Auto
            )
            .boxShadow(
                offsetY = 1.px,
                blurRadius = 3.px,
                color = sitePalette.boxShadowColor.rgb
            )
            .border(1.px, style = LineStyle.Solid, color = sitePalette.coachDetailsBoxBorder.rgb)
    ) {
        Column(
            modifier = Modifier.width(if (breakpoint <= Breakpoint.LG) 1360.px else 100.percent)
                .fillMaxHeight()
        ) {
            Box(modifier = Modifier.width(98.8.percent)) {
                Column(
                    modifier = Modifier.fillMaxWidth()
                        .padding(16.px)
                ) {
                    SectionTitle("Coach’s Recommendation")
                    FieldTitleTextNormalSimple(
                        text = getPlayerInfo.latestAssessment.trim(),
                        fontSize = 15.px,
                        fontFamily = Constants.IBM_FONT,
                        width = 100.percent,
                        marginBottom = 8.px,
                        marginTop = 8.px
                    )
                }
            }
        }
    }
}