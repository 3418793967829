import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.css.width
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.boxShadow
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxHeight
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.gap
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.overflow
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.icons.fa.FaCalendarDay
import com.varabyte.kobweb.silk.components.icons.fa.FaFutbol
import com.varabyte.kobweb.silk.components.icons.fa.FaPen
import com.varabyte.kobweb.silk.components.icons.fa.FaShirt
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import kotlinx.coroutines.launch
import org.example.zone360.components.CommonDropdown
import org.example.zone360.components.CommonDropdownWB
import org.example.zone360.components.CustomSpacer
import org.example.zone360.componentss.FieldTitleTextNormalSimple
import org.example.zone360.models.GetProfileData
import org.example.zone360.models.GetSinglePlayerData
import org.example.zone360.theme.SitePalette
import org.example.zone360.utils.AppGlobal
import org.example.zone360.utils.Constants
import org.example.zone360.utils.Res
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.attributes.placeholder
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.border
import org.jetbrains.compose.web.css.borderRadius
import org.jetbrains.compose.web.css.color
import org.jetbrains.compose.web.css.fontFamily
import org.jetbrains.compose.web.css.fontSize
import org.jetbrains.compose.web.css.outline
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.Input

@Composable
fun PlayerInfo(
    breakpoint: Breakpoint,
    sitePalette: SitePalette,
    userId: String,
    imageUrl: String){

    val scope = rememberCoroutineScope()
    var getPlayerInfo by remember { mutableStateOf(GetSinglePlayerData()) }
    var isEditing by remember { mutableStateOf(false) }
    var isAgeEditing by remember { mutableStateOf(false) }
    var isHeightEditing by remember { mutableStateOf(false) }
    var isWeightEditing by remember { mutableStateOf(false) }
    var isNumberEditing by remember { mutableStateOf(false) }
    var isPositionEditing by remember { mutableStateOf(false) }
    var isFootEditing by remember { mutableStateOf(false) }
    var isSecondaryEditing by remember { mutableStateOf(false) }
    var selectedPosition by remember { mutableStateOf("Select Position") }
    var selectedFoot by remember { mutableStateOf("Select Foot") }
    var playerPosition by  mutableStateOf("N/A")
    var secondaryPosition by remember { mutableStateOf("") }
    var foot by   mutableStateOf("N/A")

    var nationality by remember { mutableStateOf("") }
    var height by remember { mutableStateOf("") }
    var age by remember { mutableStateOf("") }
    var weight by remember { mutableStateOf("") }
    var number by remember { mutableStateOf("") }

    scope.launch {
        val getPlayerResponse = getSinglePlayer(
            domainType = "soccer",
            language = "en",
            userID = userId
        )
        getPlayerInfo = getPlayerResponse.response
        nationality = getPlayerInfo.nationality
        age = getPlayerInfo.age
        height = getPlayerInfo.height
        weight = getPlayerInfo.weight
        number = getPlayerInfo.number
        playerPosition = getPlayerInfo.position ?: "N/A"
        foot = getPlayerInfo.foot
        secondaryPosition = getPlayerInfo.secondaryPosition
    }
    Box(
        modifier = Modifier
            .fillMaxWidth()
            .backgroundColor(sitePalette.modalBackgroundColor.rgb)
            .borderRadius(15.px)
            .overflow(
                overflowY = Overflow.Hidden,
                overflowX =   Overflow.Auto
            )
            .boxShadow(
              offsetY = 1.px,
                blurRadius = 3.px,
                color = sitePalette.boxShadowColor.rgb
            )
            .border(1.px, style = LineStyle.Solid, color = sitePalette.coachDetailsBoxBorder.rgb)
            .padding(
                leftRight = if (breakpoint >= Breakpoint.LG) 30.px else 20.px,
                topBottom = if (breakpoint >= Breakpoint.LG) 20.px else 10.px
            )
    ) {
        Column(
            modifier = Modifier.width(if (breakpoint <= Breakpoint.LG) 1360.px else 100.percent)
                .fillMaxHeight()
        ) {
            Box (modifier = Modifier.width(98.8.percent)) {
                Column(
                    modifier = Modifier.fillMaxWidth()
                        .padding(topBottom = 10.px)
                ) {
                    Row(modifier = Modifier.padding(leftRight = 20.px).margin(topBottom = 10.px)) {
                        if (imageUrl.isNotEmpty()) {
                            Image(
                                src = "${Res.Images.coachImageUrl}${imageUrl}",
                                modifier = Modifier.width(120.px).height(120.px)
                                    .borderRadius(100.px)
                            )
                        } else {
                            Image(
                                src = Res.Images.userIcon,
                                modifier =  Modifier.width(120.px).height(120.px)
                                    .borderRadius(100.px)
                            )
                        }
                        Column(modifier = Modifier.margin(leftRight = 20.px).fillMaxWidth()) {
                            FieldTitleTextNormalSimple(
                                text =  getPlayerInfo.firstName,
                                fontSize = 20.px,
                                fontWeight = 600,
                                fontFamily = Constants.IBM_FONT
                            )
                            Row(
                                modifier = Modifier.margin(topBottom = 10.px),
                                verticalAlignment = Alignment.CenterVertically
                            ) {
                                FaFutbol(modifier = Modifier.color(Colors.Gray))
                                FieldTitleTextNormalSimple(
                                    text = AppGlobal.userData.organization.replaceFirstChar
                                    { if (it.isLowerCase()) it.titlecase() else it.toString() },
                                    fontSize = 16.px,
                                    fontFamily = Constants.IBM_FONT,
                                    color = sitePalette.coachInfoTextColor.rgb,
                                    marginLeft = 8.px
                                )
                            }
                            Row(
                                modifier = Modifier.margin(topBottom = 10.px).fillMaxWidth(),
                                verticalAlignment = Alignment.CenterVertically,
                                horizontalArrangement = Arrangement.SpaceBetween
                            ) {
                                Row(modifier = Modifier.gap(6.px),
                                    verticalAlignment = Alignment.CenterVertically) {
                                    Image(src = Res.Images.nationalityFlag)
                                    FieldTitleTextNormalSimple(
                                        text = " Nationality: ",
                                        fontSize = 16.px,
                                        fontFamily = Constants.IBM_FONT,
                                        color = sitePalette.coachInfoTextColor.rgb,
                                        marginLeft = 8.px
                                    )
                                    if (isEditing) {
                                        Input(
                                            type = InputType.Text,
                                            attrs = {
                                                value(nationality)
                                                onInput { event ->
                                                    nationality = event.value
                                                }
                                                onKeyDown { event ->
                                                    if (event.key == "Enter") {
                                                        isEditing = false
                                                            scope.launch {
                                                                val jsonData = """{
                                                                "uid": "$userId",
                                                                "nationality": "$nationality"
                                                                 }"""
                                                                val  updateSuccess = updateUserData(jsonData)
                                                                if (updateSuccess.data.nationality == nationality) {

                                                                    getPlayerInfo = getPlayerInfo.copy(nationality = nationality)
                                                                } else{
                                                                    println("Failed to update nationality")
                                                                }
                                                            }


                                                    }
                                                }
                                                style {
                                                    fontSize(16.px)
                                                    fontFamily(Constants.IBM_FONT)
                                                    color(sitePalette.coachInfoTextColor.rgb)
                                                    border(1.px, style = LineStyle.Solid, color = sitePalette.coachInfoTextColor.rgb)
                                                    outline("none")
                                                    borderRadius(8.px)
                                                    width(120.px)


                                                }
                                            }
                                        )
                                    } else {
                                        FieldTitleTextNormalSimple(
                                            text = nationality,
                                            fontSize = 16.px,
                                            fontFamily = Constants.IBM_FONT,
                                            color = sitePalette.coachInfoTextColor.rgb,
                                        )
                                    }
                                    FaPen(
                                        size = IconSize.XXS,
                                        modifier = Modifier
                                            .color(sitePalette.coachInfoTextColor.rgb)
                                            .cursor(Cursor.Pointer)
                                            .onClick {
                                                isEditing = !isEditing
                                            }
                                    )
                                }
                                CustomSpacer(30.px)
                                Row(modifier = Modifier.gap(6.px),
                                    verticalAlignment = Alignment.CenterVertically){
                                 FaCalendarDay(modifier = Modifier.color(Colors.Gray))
                                    FieldTitleTextNormalSimple(
                                        text = " Age: ",
                                        fontSize = 16.px,
                                        fontFamily = Constants.IBM_FONT,
                                        color = sitePalette.coachInfoTextColor.rgb,
                                        marginLeft = 8.px
                                    )
                                    if (isAgeEditing) {
                                        Input(
                                            type = InputType.Text,
                                            attrs = {
                                                value(age)
                                                placeholder("18 Years")
                                                onInput { event ->
                                                    age = event.value
                                                }
                                                onKeyDown { event ->
                                                    if (event.key == "Enter") {
                                                        isAgeEditing = false
                                                        scope.launch {
                                                            val jsonData = """{
                                                                "uid": "$userId",
                                                                "age": "$age"
                                                                 }"""
                                                            val  updateSuccess = updateUserData(jsonData)
                                                            if (updateSuccess.data.age == age) {

                                                                getPlayerInfo = getPlayerInfo.copy(age = age)
                                                            } else{
                                                                println("Failed to update age")
                                                            }
                                                        }

                                                    }
                                                }
                                                style {
                                                    fontSize(16.px)
                                                    fontFamily(Constants.IBM_FONT)
                                                    color(sitePalette.coachInfoTextColor.rgb)
                                                    border(1.px, style = LineStyle.Solid, color = sitePalette.coachInfoTextColor.rgb)
                                                    outline("none")
                                                    borderRadius(8.px)
                                                    width(90.px)

                                                }
                                            }
                                        )
                                    } else {
                                        FieldTitleTextNormalSimple(
                                            text = age,
                                            fontSize = 16.px,
                                            fontFamily = Constants.IBM_FONT,
                                            color = sitePalette.coachInfoTextColor.rgb,
                                        )
                                    }
                                    FaPen(
                                        size = IconSize.XXS,
                                        modifier = Modifier
                                            .color(sitePalette.coachInfoTextColor.rgb)
                                            .cursor(Cursor.Pointer)
                                            .onClick {
                                                isAgeEditing = !isAgeEditing
                                            }
                                    )
                                }
                            }
                            Row(
                                modifier = Modifier.margin(topBottom = 10.px).fillMaxWidth().gap(30.px),
                                verticalAlignment = Alignment.CenterVertically,
                                horizontalArrangement = Arrangement.SpaceBetween
                            ) {
                                Row(modifier = Modifier.gap(6.px),
                                    verticalAlignment = Alignment.CenterVertically) {
                                    Image(src = Res.Images.heightMeasure)
                                    FieldTitleTextNormalSimple(
                                        text = "Height: ",
                                        fontSize = 16.px,
                                        fontFamily = Constants.IBM_FONT,
                                        color = sitePalette.coachInfoTextColor.rgb,
                                        marginLeft = 8.px
                                    )
                                    if (isHeightEditing) {
                                        Input(
                                            type = InputType.Text,
                                            attrs = {
                                                value(height)
                                                placeholder("5ft 4in")
                                                onInput { event ->
                                                    height = event.value
                                                }
                                                onKeyDown { event ->
                                                    if (event.key == "Enter") {
                                                        isHeightEditing = false
                                                        scope.launch {
                                                            val jsonData = """{
                                                                "uid": "$userId",
                                                                "height": "$height"
                                                                 }"""
                                                            val  updateSuccess = updateUserData(jsonData)
                                                            if (updateSuccess.data.height == height) {

                                                                getPlayerInfo = getPlayerInfo.copy(height = height)
                                                            } else{
                                                                println("Failed to update height")
                                                            }

                                                        }

                                                    }
                                                }
                                                style {
                                                    fontSize(16.px)
                                                    fontFamily(Constants.IBM_FONT)
                                                    color(sitePalette.coachInfoTextColor.rgb)
                                                    border(1.px, style = LineStyle.Solid, color = sitePalette.coachInfoTextColor.rgb)
                                                    outline("none")
                                                    borderRadius(8.px)
                                                    width(80.px)
                                                }
                                            }
                                        )
                                    } else {
                                        FieldTitleTextNormalSimple(
                                            text = height,
                                            fontSize = 16.px,
                                            width = 55.px,
                                            fontFamily = Constants.IBM_FONT,
                                            color = sitePalette.coachInfoTextColor.rgb,
                                        )
                                    }
                                    FaPen(
                                        size = IconSize.XXS,
                                        modifier = Modifier
                                            .color(sitePalette.coachInfoTextColor.rgb)
                                            .cursor(Cursor.Pointer)
                                            .onClick {
                                                isHeightEditing = !isHeightEditing
                                            }
                                    )
                                }
                                CustomSpacer(30.px)
                                Row(modifier = Modifier.gap(6.px),
                                    verticalAlignment = Alignment.CenterVertically) {
                                    Image(src = Res.Images.weightMeasurement)
                                    FieldTitleTextNormalSimple(
                                        text = "Weight: ",
                                        fontSize = 16.px,
                                        fontFamily = Constants.IBM_FONT,
                                        color = sitePalette.coachInfoTextColor.rgb,
                                        marginLeft = 8.px
                                    )
                                    if (isWeightEditing) {
                                        Input(
                                            type = InputType.Text,
                                            attrs = {
                                                value(weight)
                                                placeholder("76 lbs")
                                                onInput { event ->
                                                    weight = event.value
                                                }
                                                onKeyDown { event ->
                                                    if (event.key == "Enter") {
                                                        isWeightEditing = false
                                                        scope.launch {
                                                            val jsonData = """{
                                                                "uid": "$userId",
                                                                "weight": "$weight"
                                                                 }"""
                                                            val  updateSuccess = updateUserData(jsonData)
                                                            if (updateSuccess.data.weight == weight) {

                                                                getPlayerInfo = getPlayerInfo.copy(weight = weight)
                                                            } else{
                                                                println("Failed to update weight")
                                                            }
                                                        }

                                                    }
                                                }
                                                style {
                                                    fontSize(16.px)
                                                    fontFamily(Constants.IBM_FONT)
                                                    color(sitePalette.coachInfoTextColor.rgb)
                                                    border(1.px, style = LineStyle.Solid, color = sitePalette.coachInfoTextColor.rgb)
                                                    outline("none")
                                                    borderRadius(8.px)
                                                    width(70.px)


                                                }
                                            }
                                        )
                                    } else {
                                        FieldTitleTextNormalSimple(
                                            text = weight,
                                            fontSize = 16.px,
                                            fontFamily = Constants.IBM_FONT,
                                            color = sitePalette.coachInfoTextColor.rgb,
                                        )
                                    }
                                    FaPen(
                                        size = IconSize.XXS,
                                        modifier = Modifier
                                            .color(sitePalette.coachInfoTextColor.rgb)
                                            .cursor(Cursor.Pointer)
                                            .onClick {
                                                isWeightEditing = !isWeightEditing
                                            }
                                    )
                                }
                                CustomSpacer(30.px)
                                Row(modifier = Modifier.gap(6.px),
                                    verticalAlignment = Alignment.CenterVertically) {
                                    FaShirt(modifier = Modifier.color(Colors.Gray))
                                    FieldTitleTextNormalSimple(
                                        text = "Number: ",
                                        fontSize = 16.px,
                                        fontFamily = Constants.IBM_FONT,
                                        color = sitePalette.coachInfoTextColor.rgb,
                                        marginLeft = 8.px
                                    )
                                    if (isNumberEditing) {
                                        Input(
                                            type = InputType.Text,
                                            attrs = {
                                                value(number)
                                                placeholder("56")
                                                onInput { event ->
                                                    number = event.value
                                                }
                                                onKeyDown { event ->
                                                    if (event.key == "Enter") {
                                                        isNumberEditing = false
                                                        scope.launch {
                                                            val jsonData = """{
                                                                "uid": "$userId",
                                                                "number": "$number"
                                                                 }"""
                                                            val  updateSuccess = updateUserData(jsonData)
                                                            if (updateSuccess.data.number == number) {

                                                                getPlayerInfo = getPlayerInfo.copy(number = number)
                                                            } else{
                                                                println("Failed to update number")
                                                            }
                                                        }

                                                    }
                                                }
                                                style {
                                                    fontSize(16.px)
                                                    fontFamily(Constants.IBM_FONT)
                                                    color(sitePalette.coachInfoTextColor.rgb)
                                                    border(1.px, style = LineStyle.Solid, color = sitePalette.coachInfoTextColor.rgb)
                                                    outline("none")
                                                    borderRadius(8.px)
                                                    width(50.px)


                                                }
                                            }
                                        )
                                    } else {
                                        FieldTitleTextNormalSimple(
                                            text = number,
                                            fontSize = 16.px,
                                            fontFamily = Constants.IBM_FONT,
                                            color = sitePalette.coachInfoTextColor.rgb,
                                        )
                                    }
                                    FaPen(
                                        size = IconSize.XXS,
                                        modifier = Modifier
                                            .color(sitePalette.coachInfoTextColor.rgb)
                                            .cursor(Cursor.Pointer)
                                            .onClick {
                                                isNumberEditing = !isNumberEditing
                                            }
                                    )
                                }
                            }
                        }
                    }
                    Row(
                        modifier = Modifier.margin(topBottom = 10.px),
                    ) {
                        Image(src =
                        when{
                            playerPosition == "GK Goalkeeper" && foot == "Right Foot" -> Res.Images.gk_svg
                            playerPosition == "RCD Right Central Defender" && foot == "Left Foot" -> Res.Images.rcd_svg
                            playerPosition == "LCD Left Central Defender" && foot == "Both Feet" -> Res.Images.lcd_svg
                            playerPosition == "RLD Right Lateral Defender" && foot == "Both Feet" -> Res.Images.rld_svg
                            playerPosition == "LLD Left Lateral Defender" && foot == "Both Feet" -> Res.Images.lld_svg
                            playerPosition == "DM Defensive Midfielder" && foot == "Both Feet" -> Res.Images.dm_svg
                            playerPosition == "AM Attacking Midfielder" && foot == "Both Feet" -> Res.Images.am_Attacking
                            playerPosition == "LW Left Wing" && foot == "Both Feet" -> Res.Images.lw_svg
                            playerPosition == "RW Right Wing" && foot == "Both Feet" -> Res.Images.rw_svg
                            playerPosition == "ST Striker" && foot == "Both Feet" -> Res.Images.st_svg
                            else -> Res.Images.grondFrame

                        }, modifier = Modifier.width(254.px).height(165.px))
                        Column (modifier = Modifier.margin(left = 24.px).gap(24.px)){
                            Column {
                                Row(modifier = Modifier.gap(10.px),
                                    verticalAlignment = Alignment.CenterVertically) {
                                FieldTitleTextNormalSimple(
                                    text = "Position:",
                                    color = sitePalette.coachInfoTextColor.rgb,
                                    fontWeight = 500,
                                    fontSize = 14.px,
                                    marginBottom = 4.px
                                )
                                    FaPen(
                                        size = IconSize.XXS,
                                        modifier = Modifier
                                            .color(sitePalette.coachInfoTextColor.rgb)
                                            .cursor(Cursor.Pointer)
                                            .onClick {
                                                isPositionEditing = !isPositionEditing
                                            }
                                    )
                                }
                                Row (modifier = Modifier.gap(6.px),
                                    verticalAlignment = Alignment.CenterVertically){
                                    if (isPositionEditing) {
                                        Box {
                                            CommonDropdown(
                                                data = listOf(
                                                    "GK Goalkeeper",
                                                    "RCD Right Central Defender",
                                                    "LCD Left Central Defender",
                                                    "RLD Right Lateral Defender",
                                                    "LLD Left Lateral Defender",
                                                    "DM Defensive Midfielder",
                                                    "AM Attacking Midfielder",
                                                    "LW Left Wing",
                                                    "RW Right Wing",
                                                    "ST Striker",
                                                ),
                                                onSelect = { selectedItem ->
                                                    selectedPosition = selectedItem
                                                    playerPosition = selectedItem
                                                    isPositionEditing = false
                                                    scope.launch {
                                                        val jsonData = """{
                                                            "uid": "$userId",
                                                            "position": "$playerPosition"
                                                        }"""
                                                        val updateSuccess = updateUserData(jsonData)
                                                        if (updateSuccess.data.position == playerPosition) {
                                                            getPlayerInfo = getPlayerInfo.copy(position = playerPosition)
                                                        } else {
                                                            println("Failed to update position")
                                                        }
                                                    }
                                                },
                                                selectedItem = selectedPosition,
                                                width = 250.px
                                            )
                                        }
                                    } else {
                                        FieldTitleTextNormalSimple(
                                            text = playerPosition,
                                            color = Color.Companion.rgba(0, 159, 147, 1f),
                                            fontWeight = 600,
                                            fontSize = 16.px,
                                        )
                                    }

                                }
                            }
                            Column {
                                Row(modifier = Modifier.gap(10.px),
                                    verticalAlignment = Alignment.CenterVertically) {
                                FieldTitleTextNormalSimple(
                                    text = "Secondary:",
                                    color = sitePalette.coachInfoTextColor.rgb,
                                    fontWeight = 500,
                                    fontSize = 14.px,
                                    marginBottom = 4.px
                                )
                                    FaPen(
                                        size = IconSize.XXS,
                                        modifier = Modifier
                                            .color(sitePalette.coachInfoTextColor.rgb)
                                            .cursor(Cursor.Pointer)
                                            .onClick {
                                                isSecondaryEditing = !isSecondaryEditing
                                            }
                                    )
                                }
                                if (isSecondaryEditing) {
                                    Input(
                                        type = InputType.Text,
                                        attrs = {
                                            value(secondaryPosition)
                                            onInput { event ->
                                                secondaryPosition = event.value
                                            }
                                            onKeyDown { event ->
                                                if (event.key == "Enter") {
                                                    isSecondaryEditing = false
                                                    scope.launch {
                                                        val jsonData = """{
                                                            "uid": "$userId",
                                                            "secondaryPosition": "$secondaryPosition"
                                                            }"""
                                                        val  updateSuccess = updateUserData(jsonData)
                                                        if (updateSuccess.data.secondaryPosition == secondaryPosition) {

                                                            getPlayerInfo = getPlayerInfo.copy(secondaryPosition = secondaryPosition)
                                                        } else{
                                                            println("Failed to update secondary")
                                                        }
                                                    }
                                                }
                                            }
                                            style {
                                                fontSize(16.px)
                                                fontFamily(Constants.IBM_FONT)
                                                color(sitePalette.coachInfoTextColor.rgb)
                                                border(1.px, style = LineStyle.Solid, color = sitePalette.coachInfoTextColor.rgb)
                                                outline("none")
                                                borderRadius(8.px)

                                            }
                                        }
                                    )
                                } else {
                                    FieldTitleTextNormalSimple(
                                        text = secondaryPosition,
                                        color = Color.Companion.rgba(240, 150, 10, 1f),
                                        fontWeight = 600,
                                        fontSize = 16.px,
                                    )
                                }
                            }
                            Column {
                                Row(
                                    modifier = Modifier.gap(10.px),
                                    verticalAlignment = Alignment.CenterVertically
                                ) {
                                    FieldTitleTextNormalSimple(
                                        text = "Foot:",
                                        color = sitePalette.coachInfoTextColor.rgb,
                                        fontWeight = 500,
                                        fontSize = 14.px,
                                        marginBottom = 4.px
                                    )
                                    FaPen(
                                        size = IconSize.XXS,
                                        modifier = Modifier
                                            .color(sitePalette.coachInfoTextColor.rgb)
                                            .cursor(Cursor.Pointer)
                                            .onClick {
                                                isFootEditing = !isFootEditing
                                            }
                                    )
                                }
                                if (isFootEditing) {
                                    Box {
                                        CommonDropdown(
                                            data = listOf(
                                                "Both Feet",
                                                "Right Foot",
                                                "Left Foot"
                                            ),
                                            onSelect = { selectedItem ->
                                                selectedFoot = selectedItem
                                                foot = selectedItem
                                                isFootEditing = false
                                                scope.launch {
                                                    val jsonData = """{
                                                            "uid": "$userId",
                                                            "foot": "$foot"
                                                        }"""
                                                    val updateSuccess = updateUserData(jsonData)
                                                    if (updateSuccess.data.foot == foot) {
                                                        getPlayerInfo = getPlayerInfo.copy(foot = foot)
                                                    } else {
                                                        println("Failed to update foot preference")
                                                    }
                                                }
                                            },
                                            selectedItem = selectedFoot,
                                            width = 150.px
                                        )
                                    }
                                } else {
                                    FieldTitleTextNormalSimple(
                                        text = foot,
                                        color = sitePalette.coachInfoTextColor.rgb,
                                        fontWeight = 600,
                                        fontSize = 16.px,
                                    )
                                }
                            }
                        }
                    }
                }
            }
        }}
}

