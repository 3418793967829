package org.example.zone360.pages.players_Details.playerRecentAssessment

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.id
import com.varabyte.kobweb.compose.ui.modifiers.left
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.maxHeight
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.onMouseDown
import com.varabyte.kobweb.compose.ui.modifiers.onMouseUp
import com.varabyte.kobweb.compose.ui.modifiers.overflow
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.position
import com.varabyte.kobweb.compose.ui.modifiers.top
import com.varabyte.kobweb.compose.ui.modifiers.transform
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.modifiers.zIndex
import com.varabyte.kobweb.silk.components.icons.fa.FaXmark
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import getClubCoachesList
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.coroutines.launch
import org.example.zone360.components.CustomSpacer
import org.example.zone360.components.SearchBar
import org.example.zone360.componentss.FieldTitleTextNormalSimple
import org.example.zone360.models.GetClubCoachesListData
import org.example.zone360.models.GetClubPlayerData
import org.example.zone360.theme.toSitePalette
import org.example.zone360.utils.AppGlobal
import org.example.zone360.utils.Constants
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.w3c.dom.HTMLElement
import requestAssessment

@Composable
fun RequestAssessment(
    onDialogDismiss: (Long) -> Unit,
    breakpoint: Breakpoint,
    modalNumber: Long,

) {
    val scope = rememberCoroutineScope()
    val sitePalette = ColorMode.current.toSitePalette()
    var searchText by remember { mutableStateOf("") }
    var coach by remember { mutableStateOf<List<GetClubCoachesListData>>(emptyList()) }
    var filteredCoach by remember { mutableStateOf<List<GetClubCoachesListData>>(emptyList()) }

    scope.launch {
      val response = getClubCoachesList(
          organization = AppGlobal.userData.organization
      )
      coach = response.data
        filteredCoach = coach
    }
    val filteredData = remember(searchText, filteredCoach) {
        if (searchText.isEmpty()) filteredCoach
        else filteredCoach.filter { coach ->
            val coachName = "${coach.firstName} ${coach.lastName}"
            coachName.contains(searchText, ignoreCase = true)
        }
    }



    Box(
        modifier = Modifier
            .width( if (breakpoint < Breakpoint.MD) 240.px else 350.px)
            .height(600.px)
            .id("invite-Coach-Modal-$modalNumber")
            .position(Position.Fixed)
            .zIndex(9)
            .top(50.percent)
            .left(50.percent)
            .transform { translate((-50).percent, (-50).percent) },
        contentAlignment = Alignment.Center,
    ) {
        Box(
            modifier = Modifier
                .padding(topBottom = 24.px)
                .width(100.percent)
                .margin(top = 10.px)
                .backgroundColor(sitePalette.modalBackgroundColor.rgb)
                .border(
                    width = 1.px,
                    color = sitePalette.textFieldBorderColor.rgb,
                    style = LineStyle.Solid
                )
                .borderRadius(4.px),
//                .overflow(Overflow.Auto),
            contentAlignment = Alignment.Center,
        ) {
            Column(modifier = Modifier.fillMaxSize()) {
                Row(
                    horizontalArrangement = Arrangement.SpaceBetween,
                    modifier = Modifier
                        .fillMaxWidth()
                        .margin(bottom = 20.px)
                        .padding(leftRight = 24.px),
                    verticalAlignment = Alignment.CenterVertically
                ) {

                    FieldTitleTextNormalSimple(
                        "Request Assessment",
                        fontSize = 20.px,
                        fontWeight = 600,
                        onClick = {}
                    )

                    FaXmark(
                        modifier = Modifier
                            .color(sitePalette.commonButtonSupportiveTextColor.rgb)
                            .cursor(Cursor.Pointer)
                            .onClick { onDialogDismiss(modalNumber) },
                        size = IconSize.LG
                    )

                }
                Row(modifier = Modifier.fillMaxWidth()
                    .padding(leftRight = 8.px)) {
                SearchBar(
                    placeholder = "Search Coach",
                    id = "session_Searchbar",
                    breakpoint = Breakpoint.LG,
                    onEnterClick = {},
                    onChange = { newSearchText ->
                        searchText = newSearchText
                    },
                    onClick = {},
                    width = 100.percent,
                    showClearButton = false,
                    height = 48.px,
                    borderColor = Colors.Transparent
                )}
                CustomSpacer(height = 10.px)
                Column(modifier = Modifier.fillMaxWidth()
                    .padding(leftRight = 24.px)
                    .maxHeight(530.px)
                    .overflow(Overflow.Auto)) {
                    filteredData.forEach { coach ->
                        CustomSpacer(height = 10.px)
                        Column(modifier = Modifier.margin(topBottom = 6.px)
                            .cursor(Cursor.Pointer)
                            .onClick {
                                scope.launch {
                                    requestAssessment(
                                        coachID = coach.uid,
                                        coachName = coach.firstName,
                                        coachEmail = coach.email
                                    )
                                    (println("Assessment Requested to ${coach.firstName}"))

                                    onDialogDismiss(modalNumber)
                                }

                            }
                        ) {
                            FieldTitleTextNormalSimple(
                                "${coach.firstName} ${coach.lastName}",
                                fontFamily = Constants.IBM_FONT,
                                fontSize = 14.px,
                                fontWeight = 500,
                                isClickable = true
                            )
                            FieldTitleTextNormalSimple(
                                coach.email,
                                fontFamily = Constants.IBM_FONT,
                                fontSize = 12.px,
                                color = Colors.Gray,
                                isClickable = true
                            )
                        }
                    }
                }
            }
        }
    }
}
