package org.example.zone360.pages.coachDetails.coachInfo

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.css.margin
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.boxShadow
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxHeight
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.gap
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.overflow
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.icons.fa.FaClock
import com.varabyte.kobweb.silk.components.icons.fa.FaFutbol
import com.varabyte.kobweb.silk.components.icons.fa.FaInfo
import com.varabyte.kobweb.silk.components.icons.fa.FaPen
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.components.icons.fa.IconStyle
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import getProfile
import getSingleCoach
import kotlinx.coroutines.launch
import org.example.zone360.components.formatSessionMinutes
import org.example.zone360.componentss.FieldTitleTextNormalSimple
import org.example.zone360.models.GetProfileData
import org.example.zone360.models.GetSingleCoachData
import org.example.zone360.models.UpdateUserData
import org.example.zone360.theme.SitePalette
import org.example.zone360.utils.AppGlobal
import org.example.zone360.utils.Constants
import org.example.zone360.utils.Res
import org.example.zone360.utils.SelectedData
import org.example.zone360.utils.SelectedLanguage
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.css.CSSSizeValue
import org.jetbrains.compose.web.css.CSSUnit
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.border
import org.jetbrains.compose.web.css.borderRadius
import org.jetbrains.compose.web.css.color
import org.jetbrains.compose.web.css.fontFamily
import org.jetbrains.compose.web.css.fontSize
import org.jetbrains.compose.web.css.outline
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Input
import org.jetbrains.compose.web.dom.Span
import updateUserData

@Composable
fun CoachInfo(
    breakpoint: Breakpoint,
    sitePalette: SitePalette,
    userId : String,
    selectedSession: SelectedData,

){


    val scope = rememberCoroutineScope()
    var isEditing by remember { mutableStateOf(false) }
    var getSingleCoachData by remember { mutableStateOf(GetSingleCoachData()) }
    var nationality by remember { mutableStateOf("") }
    // Convert SelectedData to API-compatible string
    val apiSessionType = when (selectedSession) {
        SelectedData.Matches -> "match"
        SelectedData.Training -> "training"
    }
    scope.launch {
        val res = getSingleCoach(
            organization = AppGlobal.userData.organization,
            domainType = "soccer",
            language = "en",
            sessionType = apiSessionType,
            userID =  userId)
        getSingleCoachData = res.data
        nationality = res.data.nationality
    }

    Box (modifier = Modifier.fillMaxWidth()
        .backgroundColor(sitePalette.modalBackgroundColor.rgb)
        .overflow(overflowX = Overflow.Auto, overflowY = Overflow.Hidden)
        .borderRadius(11.34.px)
        .padding(leftRight = 10.px)
        .boxShadow(
          offsetX = 0.px,
            offsetY = 2.px,
            blurRadius = 16.px,
            spreadRadius = null,
            color = sitePalette.boxShadowColor.rgb
        )){
        Column(
            modifier = Modifier.width(if (breakpoint <= Breakpoint.LG) 1360.px else 100.percent)
                .fillMaxHeight()
        ) {
     Box (modifier = Modifier.width(98.8.percent)) {

             Column(
                 modifier = Modifier.fillMaxWidth()
                     .padding(topBottom = 20.px)
             ) {
                 Row(modifier = Modifier.padding(leftRight = 20.px)) {
                     if (getSingleCoachData.imageUrl.isNotEmpty()) {
                         Image(
                             src = "${Res.Images.coachImageUrl}${getSingleCoachData.imageUrl}",
                             modifier = Modifier.width(120.px).height(120.px)
                                 .borderRadius(100.px)
                         )
                     } else {
                         Image(
                             src = Res.Images.userIcon,
                             modifier =  Modifier.width(120.px).height(120.px)
                                 .borderRadius(100.px)
                         )
                     }
                     Column(modifier = Modifier.margin(leftRight = 20.px)) {
                         println("${getSingleCoachData.firstName} ${getSingleCoachData.lastName}")
                         FieldTitleTextNormalSimple(
                             text =  "${getSingleCoachData.firstName} ${getSingleCoachData.lastName}",
                             fontSize = 20.px,
                             fontWeight = 600,
                             fontFamily = Constants.IBM_FONT
                         )

                         Row(
                             modifier = Modifier.margin(topBottom = 10.px),
                             verticalAlignment = Alignment.CenterVertically
                         ) {
                             FaFutbol(modifier = Modifier.color(Colors.Gray))
                             FieldTitleTextNormalSimple(
                                 text = " Head Coach - ${AppGlobal.userData.organization}",
                                 fontSize = 16.px,
                                 fontFamily = Constants.IBM_FONT,
                                 color = sitePalette.coachInfoTextColor.rgb,
                                 marginLeft = 8.px
                             )
                         }

                         Row(
                             modifier = Modifier.margin(topBottom = 10.px).gap(6.px),
                             verticalAlignment = Alignment.CenterVertically
                         ) {
                             Image(src = Res.Images.nationalityFlag)
                             FieldTitleTextNormalSimple(
                                 text = " Nationality: ",
                                 fontSize = 16.px,
                                 fontFamily = Constants.IBM_FONT,
                                 color = sitePalette.coachInfoTextColor.rgb,
                             )
                             if (isEditing) {
                                 Input(
                                     type = InputType.Text,
                                     attrs = {
                                         value(nationality)
                                         onInput { event ->
                                             nationality = event.value
                                         }
                                         onKeyDown { event ->
                                             if (event.key == "Enter") {
                                                 isEditing = false
                                                 scope.launch {
                                                     val jsonData = """{
                                                                "uid": "$userId",
                                                                "nationality": "$nationality"
                                                            }"""
                                                val  updateSuccess = updateUserData(jsonData)
                                                     if (updateSuccess.data.nationality == nationality) {
                                                         // Update locally only if the backend call succeeds
                                                         getSingleCoachData = getSingleCoachData.copy(nationality = nationality)
                                                     } else{
                                                         println("Failed to update nationality")
                                                     }
                                                 }

                                             }
                                         }
                                         style {
                                             fontSize(16.px)
                                             fontFamily(Constants.IBM_FONT)
                                             color(sitePalette.coachInfoTextColor.rgb)
                                             border(1.px, style = LineStyle.Solid, color = sitePalette.coachInfoTextColor.rgb)
                                             outline("none")
                                             borderRadius(8.px)

                                         }
                                     }
                                 )
                             } else {
                                 FieldTitleTextNormalSimple(
                                     text = nationality,
                                     fontSize = 16.px,
                                     fontFamily = Constants.IBM_FONT,
                                     color = sitePalette.coachInfoTextColor.rgb,
                                     id = "nationality"
                                 )
                             }
                             FaPen(
                                 size = IconSize.XS,
                                 modifier = Modifier
                                     .color(sitePalette.coachInfoTextColor.rgb)
                                     .cursor(Cursor.Pointer)
                                     .onClick {
                                         isEditing = !isEditing
                                     }
                             )
                         }
                         Row(
                             modifier = Modifier.margin(topBottom = 10.px),
                             verticalAlignment = Alignment.CenterVertically
                         ) {
                             Image(src = Res.Images.teamIcon)
                             FieldTitleTextNormalSimple(
                                 text = "Teams Managing: Juventus Academy, ABC Coaching Academy, XYZ Coaching",
                                 fontSize = 16.px,
                                 fontFamily = Constants.IBM_FONT,
                                 color = sitePalette.coachInfoTextColor.rgb,
                                 marginLeft = 8.px
                             )
                         }
                     }
                 }
                 Row(
                     modifier = Modifier.fillMaxWidth().margin(topBottom = 10.px, leftRight = 20.px)
                 ) {
                     Row(
                         modifier = Modifier
                             .width(420.px)
                             .margin(right = 20.px),
                         verticalAlignment = Alignment.CenterVertically
                     ) {
                         Box(
                             modifier = Modifier
                                 .width(60.px)
                                 .height(60.px)
                                 .backgroundColor(sitePalette.textFieldBorderColorDisabled.rgb)
                                 .borderRadius(100.px)
                                 .margin(right = 10.px),
                             contentAlignment = Alignment.Center
                         ) {
                             Image(src = Res.Images.footballicon)
                         }
                         Column {
                             Row(
                                 verticalAlignment = Alignment.CenterVertically
                             ) {
                                 SpanText(
                                     "Total Sessions",
                                     modifier = Modifier.fontFamily(Constants.FONT_FAMILY)
                                         .fontSize(15.px)
                                 )
                                 Box(
                                     modifier = Modifier
                                         .width(14.px)
                                         .height(14.px)
                                         .backgroundColor(Colors.LightGray)
                                         .borderRadius(100.px)
                                         .margin(leftRight = 10.px),
                                     contentAlignment = Alignment.Center
                                 ) {
                                     FaInfo(
                                         modifier = Modifier.color(Colors.White)
                                             .cursor(Cursor.Pointer), size = IconSize.XXS
                                     )
                                 }
                             } to
                                     SpanText(
                                         getSingleCoachData.count.toString(),
                                         modifier = Modifier.fontFamily(Constants.FONT_FAMILY)
                                             .fontWeight(
                                                 FontWeight.Bold
                                             ).margin(top = 6.px)
                                     )
                         }
                     }
                     Row(
                         modifier = Modifier
                             .width(420.px)
                             .margin(right = 20.px),
                         verticalAlignment = Alignment.CenterVertically
                     ) {
                         Box(
                             modifier = Modifier
                                 .width(60.px)
                                 .height(60.px)
                                 .backgroundColor(sitePalette.textFieldBorderColorDisabled.rgb)
                                 .borderRadius(100.px)
                                 .margin(right = 10.px),
                             contentAlignment = Alignment.Center
                         ) {
                             FaClock(
                                 modifier = Modifier.color(sitePalette.primary.rgb),
                                 size = IconSize.XL,
                                 style = IconStyle.FILLED
                             )
                         }
                         Column {
                             Row(
                                 verticalAlignment = Alignment.CenterVertically
                             ) {
                                 SpanText(
                                     "Minutes Recorded",
                                     modifier = Modifier.fontFamily(Constants.FONT_FAMILY)
                                         .fontSize(15.px)
                                 )
                                 Box(
                                     modifier = Modifier
                                         .width(14.px)
                                         .height(14.px)
                                         .backgroundColor(Colors.LightGray)
                                         .borderRadius(100.px)
                                         .margin(leftRight = 10.px),
                                     contentAlignment = Alignment.Center
                                 ) {
                                     FaInfo(
                                         modifier = Modifier.color(Colors.White)
                                             .cursor(Cursor.Pointer), size = IconSize.XXS
                                     )
                                 }
                             } to
                                     SpanText(
                                         (getSingleCoachData.recordTime/1000).formatSessionMinutes(),
                                         modifier = Modifier.fontFamily(Constants.FONT_FAMILY)
                                             .fontWeight(
                                                 FontWeight.Bold
                                             ).margin(top = 6.px)
                                     )
                         }
                     }
                     Row(
                         modifier = Modifier
                             .width(420.px)
                             .margin(right = 20.px),
                         verticalAlignment = Alignment.CenterVertically
                     ) {
                         Box(
                             modifier = Modifier
                                 .width(60.px)
                                 .height(60.px)
                                 .backgroundColor(sitePalette.textFieldBorderColorDisabled.rgb)
                                 .borderRadius(100.px)
                                 .margin(right = 10.px),
                             contentAlignment = Alignment.Center
                         ) {
                             Image(src = Res.Images.keywordIcon)
                         }
                         Column {
                             Row(
                                 verticalAlignment = Alignment.CenterVertically
                             ) {
                                 SpanText(
                                     "No. of Keywords",
                                     modifier = Modifier.fontFamily(Constants.FONT_FAMILY)
                                         .fontSize(15.px)
                                 )
                                 Box(
                                     modifier = Modifier
                                         .width(14.px)
                                         .height(14.px)
                                         .backgroundColor(Colors.LightGray)
                                         .borderRadius(100.px)
                                         .margin(leftRight = 10.px),
                                     contentAlignment = Alignment.Center
                                 ) {
                                     FaInfo(
                                         modifier = Modifier.color(Colors.White)
                                             .cursor(Cursor.Pointer), size = IconSize.XXS
                                     )
                                 }
                             } to
                                     SpanText(
                                         getSingleCoachData.totalKeywords.toString(),
                                         modifier = Modifier.fontFamily(Constants.FONT_FAMILY)
                                             .fontWeight(
                                                 FontWeight.Bold
                                             ).margin(top = 6.px)
                                     )
                         }
                     }

                 }

             }
         }
     }
    }
}
